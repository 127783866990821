import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import swal from 'sweetalert'
import cinstance from '../api/axios'
import { ArrowRighticons, AttherateIcon, LinkinkedIcons, MapPin, PhoneIcon } from './Atom/icons'



export const Footer = () => {
    const [Subscribe, setSubscribe] = useState("")

    const language_web = useSelector((state) => state.webLanguage.value)



    const validate = (email) => {


        if (!email) {
            return false

        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {

            return false
        }
        else {

            return true
        }


    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate(Subscribe)) {
            swal({
                text: "Invalid Email !!",
                icon: "warning",
                buttons: "OK"
            })
            return
        }
        try {
            await cinstance({
                url: "/subscribe/post",
                method: "POST",
                data: { email: Subscribe },
            }).then((res) => {
                if (res.status === 200) {

                    swal({
                        text: "Thankyou For Subscribing Us!",
                        icon: "success",
                        buttons: "OK"
                    })
                }
                else {
                    swal({
                        text: "Some thing went Wrong",
                        icon: "warning",
                        buttons: "OK"
                    })
                }
            })
        } catch (err) {
            console.error(err)
        }
    }


    const navigate = useNavigate();
    return (
        <div className='bg-[#1E1E1E] max-w-full h-auto'>
            <div className='container mx-auto p-4 2xl:p-0 py-8 2xl:py-12 xl:px-8'>
                <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-center '>

                    <div className="image text-white-wind space-y-4 lg:space-y-8 pb-4 lg:pb-0">
                        <a href="/" className="">
                            <img src="\Images\Logo.svg"
                                className="object-cover sm:h-auto w-auto xl:w-[150px]"
                                alt="bin haider Logo" /></a>

                        <h1 className='font-semibold text-2xl text-white-wind'>
                            {language_web === "ARE" ? ("تابعنا") : ("Follow Us")}
                        </h1>
                        <div className='flex gap-4'>
                            <a target={"_blank"} href='https://wa.me/0551400896'><img src='\whatsapp.png' className='h-6 w-6 object-cover' /></a>
                            <a href='https://www.linkedin.com/company/bin-haider-advocates-&-legal-consultants/'><img src='\LinkedIn.png' className='h-6 w-6 object-cover' /></a>

                        </div>

                    </div>
                    <div className="Contact text-white-wind space-y-3 pb-4 lg:pb-0">
                        <h1 className='font-semibold text-2xl text-white-wind'>
                            {language_web === "ARE" ? ("بيانات الاتصال ") : ("Contact Info")}</h1>

                        <div className='font-extralight text-sm flex items-center space-x-1'>
                            <span className='p-1'> <PhoneIcon /></span>
                           
                            {language_web === "ARE" ? (<span><a href="tel:04-3588777"> ۷۷۷۸۸٥۳ ۱۰</a></span>) : (<span> <a href="tel:04-3588777">04-3588777</a></span>)}


                        </div>
                        <div className='font-extralight text-sm flex items-center space-x-1'>
                            <span className='p-1'><AttherateIcon /></span>
                            <span> <a href="mailto:info@bhadvoc.com"> info@bhadvoc.com </a></span>
                        </div>
                        <div className='font-extralight text-sm flex space-x-1 tracking-tighter '>
                            <span className='p-1'><MapPin /></span>
                            {language_web === "ARE" ? (<span>1801 و 1802 ، مكتب فندق H <br />  برج ، شارع الشيخ زايد ، <br />ص. ب: 98444 ، دبي ، الإمارات العربية المتحدة. </span>) :
                                (<span>1801 & 1802, The H Hotel Office <br />  Tower, One Sheikh Zayed Rd, <br /> P.O. Box: 98444, Dubai, U.A.E.</span>)}


                        </div>
                    </div>
                    <div className="information text-white-wind space-y-3">
                        <h1 className='font-semibold text-2xl text-white-wind'>{language_web === "ARE" ? ("المعلومات") : ("Information")}</h1>
                        <ul className='font-extralight text-sm '>
                            <div className='space-y-3'>
                                <div> <Link to='/practice-area#Hospitality'><li>{language_web === "ARE" ? (<span >خدمات الضيافة</span>) : (<span>Hospitailty</span>)}</li></Link></div>
                                <div><Link to='/practice-area#Healthcare'><li>  {language_web === "ARE" ? (<span>خدمات الرعاية الصحية</span>) : (<span>Healthcare</span>)}</li></Link></div>
                                <div> <Link to='/practice-area#Insurance'><li>  {language_web === "ARE" ? (<span>خدمات التأمين</span>) : (<span>Insurance</span>)}</li></Link></div>
                                <div> <Link to='/practice-area#Education'><li>  {language_web === "ARE" ? (<span>التعليم</span>) : (<span>Education</span>)}</li></Link></div>
                                <div>  <Link to='/practice-area'><li>  {language_web === "ARE" ? (<span>الخدمات القانونية العامة</span>) : (<span>General Legal Services</span>)}</li></Link>
                                </div>
                            </div>
                        </ul>
                    </div>
                    {/***Subscribe  */}
                    <div className="bg-true-gray-800 text-white-wind space-y-3 w-[318px] h-[232px]  mt-4 xl:mt-0">
                        <div className='container mx-auto ml-4 py-[36px]'>
                            <h1 className='font-semibold text-2xl pb-4 text-white-wind'>
                                {language_web === "ARE" ? ("أشترك") : ("Subscribe")}</h1>
                            <form onSubmit={handleSubmit}>
                                <div className="flex items-center w-[300px] h-[48px]">

                                    <input
                                        type="email"
                                        name={'email'}
                                        onChange={(e) => setSubscribe(e.target.value)}
                                        className="block p-[7px] h-[45px] w-[232px] focus:outline-none appearance-none rounded-none border-r-0 focus:ring-0 text-true-gray-500  bg-white-wind" placeholder="Email Address*" required />
                                    <button type='submit' className="w-[48px] h-[46px] p-2.5 text-sm rounded-none border-r-0 font-medium text-white-wind bg-primary-wind focus:ring-0 focus:outline-none ">
                                        <ArrowRighticons />
                                    </button>

                                </div>
                            </form>
                            <p className='text-sm font-extralight pt-4 w-[300px]'>
                                {language_web === "ARE" ? ("اشترك معنا ليصلك كل جديد عن المستشارين القانونيين والباحث القانوني والمحامي") : (" Subscribe with us to get all updatation about Legal Consultants, Legal Researcher and Advocate")}

                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className=''>
                <div className='bg-white-wind w-auto p-[0.5px] ' />
                <>
                    <div className='container mx-auto pb-8  p-4 2xl:p-0 py-8 2xl:py-12 xl:px-8'>
                        <div className='flex items-center justify-center'>
                            <p className='font-extralight text-[10px] sm:text-sm text-start  pt-4 text-white-wind tracking-tighter'>COPYRIGHT @ 2023. Developed by
                                <span className='text-[#005e9c] tracking-tighter font-medium px-1'><a href='https://www.plaxonic.com' target="_blank">Plaxonic</a> </span></p>
                            <p className='flex-1' />

                            <p className='cursor-not-allowed font-extralight text-[10px] sm:text-sm text-start pt-4 lg:pt-0 text-white-wind tracking-tighter space-x-4'>
                                <span> Privacy</span>
                                <span>Terms & Conditions</span>
                            </p>


                        </div>
                    </div>
                </>

            </div>
        </div>
    )
}
