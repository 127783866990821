/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import axios from "axios";
import RegistrationForm from "./RegistrationForm";
// import { useNavigate } from "react-router-dom";
// import Profile from "./Profile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { Navigate } from "react-router-dom";

const LoginForm = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const storedUserId = localStorage.getItem("userId");  
  const storedToken = localStorage.getItem("token");  
  const [formErrors, setFormErrors] = useState({});
  const [message, setMessage] = useState("");
  const [messageTimeout, setMessageTimeout] = useState(null);
  // const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [type, setType] = useState("login")
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');  
  // const navigate = useNavigate();

  const refresh = () => window.location.reload(true)


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.binhaider.dev.client.kloudlite.io/userauthor/login",
        formData
      );

      if (response.data.token) {
        setMessage("Login successful");
        setType("login");
        setFormErrors({});
        const userId = response.data.currentuser._id; // Extract user ID
        const token = response.data.token; // Extract token

        // Store user ID and token in localStorage
        localStorage.setItem("userId", userId);
        localStorage.setItem("token", token);

        const timeoutId = setTimeout(() => {
          props.closeModal();
          setMessage("");
        }, 2000);
        refresh()

        // navigate(`/profile/${userId}`, { state: { token } });
      } else if (response.data.message === "Invalid Password") {
        setMessage("Password is incorrect. Please try again.");
      } else if (response.data.message === "User not registered") {
        setMessage("User is not registered. Please register.");
      } else {
        setMessage("User is not registered. Please register.");
      }

      if (messageTimeout) {
        clearTimeout(messageTimeout);
      }
      // console.log('API Response:', response.data);
      const timeoutId = setTimeout(() => {
        setMessage("");
      }, 5000);

      setMessageTimeout(timeoutId);
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again.");

      if (messageTimeout) {
        clearTimeout(messageTimeout);
      }

      const timeoutId = setTimeout(() => {
        setMessage("");
      }, 5000);

      setMessageTimeout(timeoutId);
    }
  };

  const switchToLogin = () => {
    setType("login");
  };

  const switchToRegister = () => {
    setType("register");
  };


  const handleForgotPassword = async () => {
    // Check if the email field is empty
    if (!formData.email) {
      setMessage('Please enter your email to reset your password.');
      setTimeout(() => {
        setMessage('');
      }, 3000);
      return;
    }

    try {
      const response = await axios.put(
        'https://api.binhaider.dev.client.kloudlite.io/userauthor/forgetpassword',
        { email: formData.email }
      );

      if (response.status === 200) {
        setMessage('Password reset email sent successfully.');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      } else {
        setMessage('Password reset email could not be sent. Please try again later.');
        setTimeout(() => {
          setMessage('');
        }, 3000);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
      setTimeout(() => {
        setMessage('');
      }, 3000);
    }
  };



  return (
    <div>
      <div className="px-16 py-8 space-y-8">
        <div className="">
          <div className="flex space-x-4 px-24">
            <div
              onClick={switchToLogin}
              className={`border-b-[1px] ${type === "login"
                ? "text-primary border-primary"
                : "text-true-gray-400 border-true-gray-400"
                } text-sm font-semibold cursor-pointer`}
            >
              Login
            </div>
            <div
              onClick={switchToRegister}
              className={`border-b-[1px] ${type === "register"
                ? "text-primary border-primary"
                : "text-true-gray-400 border-true-gray-400"
                } text-sm font-semibold cursor-pointer`}
            >
              Register
            </div>
          </div>
        </div>
        {/* <div className="bg-white w-auto p-[0.5px]" /> */}

        {type === "login" ? (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-y-4">
              <div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address*"
                  required
                  className="w-full p-2 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray text-true-gray-400"
                />
                {formErrors.email && (
                  <span className="text-red-600 text-sm">{formErrors.email}</span>
                )}
              </div>

              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password*"
                  required
                  className="w-full p-2 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray-400"
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
                >
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </span>
              </div>

              <div className="pt-4">
                <button
                  type="submit"
                  className="w-full bg-primary text-white py-2 rounded hover:bg-primary-dark"
                >
                  Login
                </button>

                        {/* Forget Password Button  */}

                {/* <button
                  onClick={handleForgotPassword}
                  className="mt-5 float-right text-primary-wind text-base font-bold underline underline-offset-2 cursor-pointer"
                >
                  Forgot Password
                </button> */}
              </div>
            </div>
          </form>
        ) : (
          <div>
            <RegistrationForm type={"register"} setType={setType}/>
          </div>
        )}
        {message && (
          <div
            className={
              message.includes("successful") ? "text-green-600" : "text-red-600"
            }
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
