import { UserLayout } from "../Layout/UserLayout";

const PrivacyPolicy = () => {
    return (
    <UserLayout>
        <div className="container mx-auto">
      <div className="flex flex-col items-center p-20 text-white">
        <p className="text-3xl font-semibold">Privacy Policy</p>
  
        <div className="text-justify p-20">
          Users have the right to request how their personal data is being stored
          and used. Personal data might include anything from name, contact
          details, addresses, etc. We use the information for better user
          experience, communication and other app-related services. We do not
          share the same with any third-party users except when required to be
          done under the law. User location data is protected as private
          information. The information thus collected is not shared with any
          third-party user and is exclusively used by the app to enhance your
          experience. We ensure users have a safe experience with the app, and
          thus strive to prioritize the security of user data. Primarily the
          information stored is used to provide, test, improve, promote, and
          personalize our services. We take the required steps to ensure
          protection of your personal information from unauthorized access and
          unlawful processing. We store information as long as we require or until
          the law allows us to. We follow industry standards on information
          security management to protect sensitive information.
        </div>
      </div>
      </div>
      </UserLayout>
    );
  };
  
  export default PrivacyPolicy;