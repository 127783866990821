import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from '../src/Pages/Home'
import { AboutUs } from "./Pages/AboutUs";
import ClientBlog from "./Pages/ClientBlog";
import BlogView from "./Pages/ClientBlogModal";
import ClientOurTeam from "./Pages/ClientOurTeam";
import ClientPracticeArea from "./Pages/ClientPracticeArea";
import ContactUs from "./Pages/ContactUs";
import { useSelector, useDispatch } from 'react-redux'
import { changeWebLanguage } from './component/slice/languageSlice'
import { useEffect } from "react";
import ScrollToTop from "./component/scrollToTop";
import ClientOurTeamView from "./Pages/ClientOurTeam-view";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Profile from "./component/Profile";
import {Header} from "./component/Header";

function App() {
  const language_web = useSelector((state) => state.webLanguage.value)

  const dispatch = useDispatch()

  useEffect(() => {
    
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en")
      dispatch(changeWebLanguage("en"))

    } else {
      dispatch(changeWebLanguage(localStorage.getItem("lang")))

    }
  }, [])

  return (
    <div className={language_web === "ARE" && `arabic-format`}>
      <BrowserRouter>
      <ScrollToTop/>
      <Header/>
        <Routes>      
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/our-team" element={<ClientOurTeam />} />
          <Route path="/our-team-view" element={<ClientOurTeamView/>} />
          <Route path="/blog" element={<ClientBlog />} />
          <Route path="/blog-view" element={<BlogView />} />
          <Route path="/practice-area" element={<ClientPracticeArea />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          {/* <Route path="/profile/:userId" element={<Profile />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
