
import { ChattingCard } from '../component/ChattingCard'
import { Footer } from '../component/Footer'
import { Header } from '../component/Header'
import { SocialMedia } from '../component/SocialMedia'

export const UserLayout = ({ children }) => {
  return (
    <div className='max-w-auto h-auto bg-white'>


      <Header />

      <div className='overflow-x-hidden mt-20 '>
        <div className='bg-true-gray-900'>
          <div className=''>
          {children}
          </div>
         
        
          {/**Social media */}
          {/* <div>
            <SocialMedia />
          </div> */}
          {/**Chatting */}
          {/* <div>
            <ChattingCard />
          </div> */}

        </div>
      </div>

      <Footer />

    </div>
  )
}

