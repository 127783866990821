/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { PrimaryButton } from "./Atom/form-field";
import Modal from "react-modal";
import { Consultation } from "./Consultation";
import { useDispatch, useSelector } from "react-redux";
import { changeWebLanguage } from "./slice/languageSlice";
import { HeaderData } from "../helper/Static data/header";
import LoginForm from "./LoginForm";
import ProfileImage from "../Profileimage/account.png";
// import RegistrationForm from "./RegistrationForm";
import { ConsultationWithoutemail } from "./Consultaionname";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

export const Header = () => {
  const [open, setOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const profileDropdownRef = useRef(null);

  const loginModalRef = useRef(null);
  const registerModalRef = useRef(null);

  const dispatch = useDispatch();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const toggleLoginModal = () => {
    setLoginModalOpen(!loginModalOpen);
  };

  const navigate = useNavigate();

  async function fetchUserData(userId, token) {
    try {
      const response = await fetch(
        `https://api.binhaider.dev.client.kloudlite.io/userauthor/getdata/${userId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const userData = await response.json();
        // console.log("Fetched user data:", userData);
        return userData;
      } else {
        throw new Error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  }

  useEffect(() => {
    // Get userId and token from local storage
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    if (userId && token) {
      // Fetch user data
      fetchUserData(userId, token).then((data) => {
        if (data !== null) {
          setUserData(data);
          // console.log("User data set:", data);
        }
      });
    }
  }, []);



  const handleDropdownClick = () => {
    if (!isLoggedIn) {
      // If not logged in, open the login modal
      setLoginModalOpen(true);
    } else {
      // If logged in, toggle the profile dropdown
      setProfileDropdownOpen(!profileDropdownOpen);
    }
  };

  // const handleDropdownLeave = () => {
  //   setDropdownHovered(false);
  //   setProfileDropdownOpen(false);
  // };

  // const handleLoginClick = () => {
  //   setLoginModalOpen(true);
  // };

  // const handleRegisterClick = () => {
  //   setRegisterModalOpen(true);
  // };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: "0",
      zIndex: 12,
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#171717",
      marginTop: "20px",
      overflowY: "auto",
      maxHeight: "80vh",
    },
  };

  const handleHeader = () => {
    setOpen(!open);
  };

  const handleLoginSuccess = (userData) => {
    // This function is called when the login is successful.
    // Set the user data in the state.
    setUserData(userData);
    setIsLoggedIn(true); // Set the logged-in status to true
    setLoginModalOpen(false); // Close the login modal
  };

  let menuRef = useRef()

useEffect(()=>{
  let handler = (e)=>{
    if(!menuRef?.current?.contains(e.target)){
      setProfileDropdownOpen(false)
    }
  }
  // document.addEventListener("mousedown",handler)
})

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        profileDropdownOpen &&
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target) &&
        (!loginModalRef.current ||
          !loginModalRef.current.contains(event.target)) &&
        (!registerModalRef.current ||
          !registerModalRef.current.contains(event.target))
      ) {
        // Close profile dropdown, login modal, and register modal if open
        setProfileDropdownOpen(false);
        setLoginModalOpen(false);
        setRegisterModalOpen(false);
      }
    };

    // Attach a click event listener to the document body
    document.body.addEventListener("click", handleDocumentClick);

    return () => {
      // Remove the click event listener when the component unmounts
      document.body.removeEventListener("click", handleDocumentClick);
    };
  }, [profileDropdownOpen]);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    const storedToken = localStorage.getItem("token");

    if (storedUserId && storedToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleMyProfileClick = () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    if (userId && token) {
      navigate(`/profile/${userId}`, { state: { token } });
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    setUserData(null);
    setIsLoggedIn(false);
    setProfileDropdownOpen(false);
    navigate("/");
  };

  const language_web = useSelector((state) => state.webLanguage.value);

  return (
    <header>
      <nav className="bg-true-gray-300 w-screen h-20 fixed z-20 inset-0 ">
        <div className="container mx-auto p-4 lg:px-4 xl:px-0 pt-3.5">
          <div className="arabic-format1 justify-between flex sm:gap-x-8 items-center">
            <Link to="/" className="">
              <img
                src="\Images\Logo.svg"
                className="object-cover sm:h-auto w-auto xl:w-[150px]"
                alt="bin haider Logo"
              />
            </Link>

            <div className="flex gap-x-1 xl:gap-x-3 items-center lg:order-2">
              {/* <div className="flex justify-between gap-x-4 xl:gap-x-10 items-center lg:order-2"> */}
              {/* <img src='\Images\languagelobo.svg'alt='language-logo' className='pr-12'/>   */}

              {isLoggedIn ? (
                <>
                  <PrimaryButton
                    onclick={openModal}
                    label={
                      language_web === "ARE"
                        ? "الحصول علي استشاره"
                        : "Get Consultation"
                    }
                  />
                  <Modal
                    ariaHideApp={false}
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <ConsultationWithoutemail closeModal={closeModal} />
                  </Modal>
                </>
              ) : (
                <>
                  <PrimaryButton
                    onclick={openModal}
                    label={
                      language_web === "ARE"
                        ? "الحصول علي استشاره"
                        : "Get Consultation"
                    }
                  />
                  <Modal
                    ariaHideApp={false}
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <Consultation closeModal={closeModal} />
                  </Modal>
                </>
              )}
              {/* Login/Register button  */}

              {/* <div
                className="relative"
               
                onClick={handleDropdownClick}
                ref={menuRef}

              >
                <div
                  className="cursor-pointer"
                >
                  
                  <div
                    className="cursor-pointer"
                  >
                    {userData ? (
                      <div className="flex items-center">
                        
                        <span className=" w-12 h-12 text-3xl bg-primary-wind text-white rounded-full flex items-center justify-center">
                          {userData.name.toUpperCase().charAt(0)}
                        </span>
                      </div>
                    ) : (
                      <img
                        src={ProfileImage}
                        alt="Profile Icon"
                        className="w-8 h-8 rounded-full fill-current text-primary-wind"
                      />
                    )}
                  </div>
                </div>

                {profileDropdownOpen && (
                  <div className=" absolute top-full mt-2 w-40 bg-white border border-gray-200 shadow-lg ">
                    <ul className="  flex-col w-full ">
                     
                      <li className=" w-full">
                       <button
                          onClick={handleMyProfileClick}
                          className="block px-3 first:py-3 text-left w-full  text-gray-800 hover:bg-gray-100 hover:text-primary-wind focus:outline-none focus:bg-gray-100"
                        >
                          <FontAwesomeIcon icon={faUser} className="mr-2" /> My Profile
                        </button>
                      </li>
                      <li className="w-full  ">
                       <div className="w-full">
                        <button
                          onClick={handleLogout}
                          className="text-left px-3 block py-3 w-full  text-gray-800 hover:bg-gray-100 hover:text-primary-wind focus:outline-none focus:bg-gray-100"
                        >
                          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" /> Logout
                        </button>
                        </div>
                      </li>
                      
                    </ul>
                  </div>
                )}

              </div> */}

             
              <Modal
                isOpen={loginModalOpen}
                onRequestClose={() => setLoginModalOpen(false)}
                style={customStyles}
                contentLabel="Login Modal"
                ref={loginModalRef} // Set the ref for the login modal
              >
                <LoginForm
                  onLoginSuccess={handleLoginSuccess}
                  closeModal={toggleLoginModal}
                  type={"login"}
                />
                ;
              </Modal>

              <Modal
                isOpen={registerModalOpen}
                onRequestClose={() => setRegisterModalOpen(false)}
                style={customStyles}
                contentLabel="Register Modal"
                ref={registerModalRef} // Set the ref for the register modal
              >
                <LoginForm
                  onLoginSuccess={handleLoginSuccess}
                  closeModal={toggleLoginModal}
                  type={"register"}
                />
              </Modal>

              <button
                onClick={handleHeader}
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none "
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            {/***Large screen */}
            <div
              className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
              id="mobile-menu-2"
            >
              <ul className="flex flex-col mt-4 font-medium lg:flex-row  lg:gap-x-4 2xl:gap-x-8 lg:mt-0">
                {HeaderData?.[language_web]?.map((value, index) => {
                  return (
                    <li key={index}>
                      <NavLink
                        to={value.path}
                        aria-current="page"
                        className={({ isActive }) =>
                          isActive
                            ? "text-base font-semibold text-primary-wind whitespace-nowrap"
                            : "text-base font-normal text-true-gray-600 whitespace-nowrap"
                        }
                      >
                        <span>{value.title}</span>
                      </NavLink>
                    </li>
                  );
                })}

                <li>
                  {/***Language Selection */}
                  <div className="pl-2 pr-2 space-x-1 text-sm font-extralight text-true-gray-600">
                    {language_web === "ARE" ? (
                      <span
                        onClick={() => {
                          dispatch(changeWebLanguage("ARE"));
                          localStorage.setItem("lang", "ARE");
                        }}
                        className={`text-sm xl:text-sm cursor-pointer underline text-primary-wind`}
                      >
                        العربية
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          dispatch(changeWebLanguage("ARE"));
                          localStorage.setItem("lang", "ARE");
                        }}
                        className={`text-sm xl:text-sm text-true-gray-600 cursor-pointer`}
                      >
                        العربية
                      </span>
                    )}

                    <span>/</span>
                    {language_web === "en" ? (
                      <span
                        onClick={() => {
                          dispatch(changeWebLanguage("en"));
                          localStorage.setItem("lang", "en");
                        }}
                        className="text-sm xl:text-sm font-semibold text-primary-wind cursor-pointer hover:underline active:bg-blue-500"
                      >
                        English
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          dispatch(changeWebLanguage("en"));
                          localStorage.setItem("lang", "en");
                        }}
                        className="text-sm xl:text-sm font-semibold text-true-gray-600  cursor-pointer "
                      >
                        English
                      </span>
                    )}
                  </div>
                </li>

                {/* <li>
                  <NavLink
                    to={"/about-us"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm font-semibold text-primary-wind lg:pr-4"
                        : "text-sm font-extralight text-true-gray-600 lg:pr-4"
                    }
                  >
                    About us

                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/our-team"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm font-semibold text-primary-wind"
                        : "text-sm font-extralight text-true-gray-600"
                    }
                  >
                    Our team

                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/practice-area"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm font-semibold text-primary-wind"
                        : "text-sm font-extralight text-true-gray-600"
                    }
                  >
                    Practice area

                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/blog"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm font-semibold text-primary-wind"
                        : "text-sm font-extralight text-true-gray-600"
                    }
                  >
                    Blog

                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={"/contact-us"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-sm font-semibold text-primary-wind"
                        : "text-sm font-extralight text-true-gray-600"
                    }
                  > Contact us

                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        {/***small Screen */}
        {open ? (
          <div
            className={`absolute  sm:mt-[2px] flex w-screen items-center justify-between bg-true-gray-300 z-20 md:order-1 lg:hidden`}
            id="navbar-sticky"
          >
            <div className="container mx-auto">
              <ul className="flex flex-col px-8 py-4 mt-0 rounded-lg space-y-2 md:mt-0 md:text-sm md:font-medium ">
                {HeaderData?.[language_web]?.map((value) => {
                  return (
                    <li>
                      <NavLink
                        to={value.path}
                        aria-current="page"
                        className={({ isActive }) =>
                          isActive
                            ? "text-base font-semibold text-primary-wind"
                            : "text-base font-normal text-true-gray-600"
                        }
                      >
                        <span>{value.title}</span>
                      </NavLink>
                    </li>
                  );
                })}

                {/* <li>
                    <NavLink
                      to="/"
                      // aria-current="page"
                      className={({ isActive }) =>
                        isActive
                          ? "text-sm font-semibold text-primary-wind"
                          : "text-sm font-extralight text-true-gray-600"
                      }
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/about-us"
                      className={({ isActive }) =>
                        isActive
                          ? "text-sm font-semibold text-primary-wind"
                          : "text-sm font-extralight text-true-gray-600"
                      }
                    >
                      About us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/our-team"}
                      className={({ isActive }) =>
                        isActive
                          ? "text-sm font-semibold text-primary-wind"
                          : "text-sm font-extralight text-true-gray-600"
                      }
                    >
                      Our team
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/practice-area"}
                      className={({ isActive }) =>
                        isActive
                          ? "text-sm font-semibold text-primary-wind"
                          : "text-sm font-extralight text-true-gray-600"
                      }
                    >
                      Practice Area
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/blog"}
                      className={({ isActive }) =>
                        isActive
                          ? "text-sm font-semibold text-primary-wind"
                          : "text-sm font-extralight text-true-gray-600"
                      }
                    >
                      Blog
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"/contact-us"}
                      className={({ isActive }) =>
                        isActive
                          ? "text-sm font-semibold text-primary-wind"
                          : "text-sm font-extralight text-true-gray-600"
                      }
                    >
                      Contact us
                    </NavLink>
                  </li> */}

                <li>
                  <div className=" space-x-1 text-sm font-extralight text-true-gray-600">
                    {language_web === "ARE" ? (
                      <span
                        onClick={() => {
                          dispatch(changeWebLanguage("ARE"));
                          localStorage.setItem("lang", "ARE");
                        }}
                        className={`text-sm xl:text-sm cursor-pointer underline text-primary-wind`}
                      >
                        العربية
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          dispatch(changeWebLanguage("ARE"));
                          localStorage.setItem("lang", "ARE");
                        }}
                        className={`text-sm xl:text-sm text-true-gray-600 cursor-pointer`}
                      >
                        العربية
                      </span>
                    )}

                    <span>/</span>
                    {language_web === "en" ? (
                      <span
                        onClick={() => {
                          dispatch(changeWebLanguage("en"));
                          localStorage.setItem("lang", "en");
                        }}
                        className="text-sm xl:text-sm font-semibold text-primary-wind cursor-pointer hover:underline active:bg-blue-500"
                      >
                        English
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          dispatch(changeWebLanguage("en"));
                          localStorage.setItem("lang", "en");
                        }}
                        className="text-sm xl:text-sm font-semibold text-true-gray-600 cursor-pointer"
                      >
                        English
                      </span>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
      </nav>
    </header>
  );
};
