import React, { useCallback, useEffect, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useSelector } from "react-redux";
import cinstance from "../api/axios";
import { AboutUsData } from "../helper/Static data/aboutus";

import { Breadcrumb } from "../component/Breadcrumb";
import { InfoCard, MessageBox } from "../component/Card";
import { UserLayout } from "../Layout/UserLayout";
import useWindowDimensions from "../component/ScreenWidth";

export const AboutUs = () => {
  const [globaldata, setGlobalData] = useState()
  const [CMSAboutData, setCMSAboutData] = useState(null)
  const language_web = useSelector((state) => state.webLanguage.value)
  const getCMSData = useCallback(async () => {
    try {
      await cinstance({
        url: "/cmsaboutcontent/get-about-content",
        method: "GET",
      }).then((res) => {
        setCMSAboutData(res.data)
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const getGlobalData = useCallback(async () => {
    try {
      await cinstance({
        url: "/page-intro/find/63e393105a0223b065c4b045",
        method: "GET",
      }).then((res) => {
        setGlobalData(res.data)
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getCMSData()
    getGlobalData()
    return () => {

    }
  }, [])

 

  return (
    <UserLayout>
      <Breadcrumb image={"about-banner"} />
       {/* label={language_web === "ARE" ? ("معلومات عنا") : ("About Us")}  */}
      {/* <div className='bg-about-banner'></div>  */}

      <div className="flex justify-center xl:py-[70px] ">
        <InfoCard
          title={language_web === "ARE" ? ("معلومات عنا") : ("About Us")}
          paragragh={CMSAboutData?.intro?.[language_web]?.introduction}
        />
      </div>
      {/* <div className="container"> */}
      <div className="pb-0">
        <div className="flex flex-wrap md:flex-col-reverse xl:flex-row xl:gap-x-24 justify-start items-center ">
          {/* <div className="flex justify-start items-center xl:gap-x-12"> */}
          <div className=" pb-16  md:pt-12 lg:py-0">
            <div className="grid grid-cols-2 ">
              <div className="bg-primary-wind text-white-wind w-auto lg:w-[400px] xl:w-[420px] 2xl:w-[500px] h-auto lg:h-[184px]">
                <div className="container mx-auto 2xl:px-12 p-4">
                  <h1 className="text-xl lg:text-2xl font-semibold text-white-wind">
                    {CMSAboutData?.corevalue?.[language_web]?.title?.title1}
                  </h1>
                  <p className="text-[14px] leading-[24px] font-extralight pt-4 text-start">
                    {CMSAboutData?.corevalue?.[language_web]?.desc?.desc1}
                  </p>
                </div>
              </div>

              <div className="bg-true-gray-800 text-white-wind w-auto lg:w-[400px] xl:w-[420px] 2xl:w-[500px] h-auto lg:h-[184px]">
                <div className="container mx-auto 2xl:px-12 p-4 ">
                  <h1 className="text-xl lg:text-2xl font-semibold text-white-wind">
                    {CMSAboutData?.corevalue?.[language_web]?.title?.title2}

                  </h1>
                  <p className="text-[14px] leading-[24px] font-extralight pt-4 text-start">
                    {CMSAboutData?.corevalue?.[language_web]?.desc?.desc2}
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div className="bg-true-gray-800 text-white-wind w-auto  lg:w-[400px]  xl:w-[420px] 2xl:w-[500px] h-auto lg:h-[184px]">
                <div className="container mx-auto  2xl:px-12 xl:py-8 p-4 ">
                  <h1 className="text-xl lg:text-2xl font-semibold text-white-wind">
                    {CMSAboutData?.corevalue?.[language_web]?.title?.title3}
                  </h1>
                  <p className="text-[14px] leading-[24px] font-extralight pt-4 text-start">
                    {CMSAboutData?.corevalue?.[language_web]?.desc?.desc3}
                  </p>
                </div>
              </div>
              <div className="bg-primary-wind text-white-wind w-auto  lg:w-[400px] xl:w-[420px] 2xl:w-[500px] h-auto lg:h-[184px]">
                <div className="container mx-auto p-4 xl:py-8 2xl:px-12 ">
                  <h1 className="text-xl lg:text-2xl font-semibold text-white-wind">
                    {CMSAboutData?.corevalue?.[language_web]?.title?.title4}

                  </h1>
                  <p className="text-[14px] leading-[24px] font-extralight pt-4 text-start">
                    {CMSAboutData?.corevalue?.[language_web]?.desc?.desc4}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block ">
          {language_web === "ARE" ? (<div className=" text-center text-white-wind  pr-12 xl:pr-0">
            <h1 className="text-4xl font-semibold tracking-tighter pb-2">
            قيمنا الأساسية 
            </h1>
            <h3 className="text-2xl font-semibold tracking-tighter md:pb-4 xl:pb-0">
            ما نؤمن به!
            </h3>
          </div>) : (<div className=" text-center text-white-wind  pr-12 xl:pr-0">
            <h1 className="text-4xl font-semibold tracking-tighter pb-2">
              Our Core Values
            </h1>
            <h3 className="text-2xl font-semibold tracking-tighter md:pb-4 xl:pb-0">
              What we believe in!
            </h3>
          </div>)}
          </div>

        </div>
      </div>


      {/** <div className='h-auto w-screen'>
        <div className='relative z-10 '>
          <div className='absolute inset-0'>
            <div className='container mx-auto'>
              <div className='grid  xl:grid-cols-2 justify-between  gap-6'>
                <div className='text-white'>
                  <div className='xl:py-[40px]'>
                    {language_web === "ARE" ? (<h1 className={`uppercase font-semibold text-2xl xl:text-4xl pb-6 `}>كلمة الرئيس التنفيذي</h1>)
                      : (<h1 className={` font-semibold text-2xl xl:text-4xl  pb-6 `}>CEO's MESSAGE</h1>)}

                    <p className='font-extralight text-sm xl:text-sm leading-8 text-start'
                      dangerouslySetInnerHTML={{ __html: CMSHomeData?.CEO?.[language_web]?.message }} />
                    <div className='flex gap-4 pt-6'>
                      <div className='bg-[#00609C] p-[1px] w-1 h-[48px]' />
                      {language_web === "ARE" ? (
                        <div>
                          <h1 className='text-xl xl:text-2xl font-semibold  text-[#00609C]'>عيسى بن حيدر</h1>
                          <h2 className='text-sm xl:text-lg font-extralight  '>الرئيس التنفيذي</h2>
                        </div>
                      ) : (
                        <div>
                          <h1 className='text-xl xl:text-2xl font-semibold  text-[#00609C]'>ISA BIN HAIDER</h1>
                          <h2 className='text-sm xl:text-lg font-extralight  '>CEO & Founder of Bin Haider</h2>
                        </div>
                      )}

                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>

          <div className='flex'>
            <div className='bg-true-gray-800 text-white xl:h-[455px] w-[50%]'></div>
            <div className='bg-transparent h-auto w-[50%]'>
              <img src={CMSHomeData?.CEO?.img} alt='ceo_image' className="w-auto h-auto xl:h-[455px] object-cover" />
            </div>
          </div>
        </div>
      </div> */}

      <div className='h-auto w-auto'>
        <div className='relative z-10 '>
          <div className='lg:absolute inset-0'>
            <div className="container mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-x-48">
                <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInLeft" className="xl:flex-none">
                  <MessageBox desc={globaldata?.[language_web]?.vision} />
                </AnimationOnScroll>
                <div className=" my-12 lg:mt-[100px] flex justify-center ">
                  <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInRight">
                    <div className="xl:pl-[70px] mb-4 sm:mb-8 lg:mb-0">
                    <img src="\Images\about-us\ceo-stand.png"
                      className="h-[472px] w-[420px] object-cover "
                      alt="ceo"
                    />
                    </div>
                  
                  </AnimationOnScroll>

                </div>

              </div>
            </div>
          </div>
         
          <div className='flex justify-center items-center '>
            <div className='bg-primary text-white h-1 w-0 lg:h-[300px] lg:w-[50%]'></div>
            <div className='bg-transparent  h-1 w-0 lg:h-[674px] lg:w-[50%]'></div>
          </div>
        
        </div>
      </div>


      {/* <div className="pb-20 lg:py-[70px]">
        <div className="">
          {/* <div className="flex xl:flex-wrap flex-col-reverse sm:flex-row xl:gap-x-32 2xl:gap-12 items-center"> */}
      {/* <div className="flex items-center flex-wrap  sm:justify-start xl:gap-x-12">
            <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInLeft" className="xl:flex-none">
              <MessageBox bgcolor={"#00609C"} desc={globaldata?.[language_web]?.vision} />
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInRight">
              <div className="lg:flex-initial">
                <img src="\Images\about-us\ceo-stand.png"
                  className="h-auto  lg:h-[530px] md:w-[360px]  xl:w-auto xl:object-cover"
                  alt="ceo"
                />
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div> */}
      {/* </div>  */}

      {/* <div className="pt-[120px] pb-[70px]">

        <div className="bg-primary h-[472px] w-[50%]">
          <div className="absolute inset-0 top-[1104px] bottom-8">
            <div className="container mx-auto">
              <div className="flex  justify-start gap-x-12 ">
              <MessageBox desc={globaldata?.[language_web]?.vision} /> 
              <div className="object-contain h-[674px] w-[570px] ">
                  <img src="\Images\about-us\ceo-stand.png" />
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>    */}




    </UserLayout>
  );
};
