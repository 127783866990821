import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Breadcrumb } from '../component/Breadcrumb'
import { UserLayout } from '../Layout/UserLayout'
import { BlogCard } from '../component/Card';
import { LinkinkedIcons, QuotesLowerIcon, QuotesUpperIcon, WhatsappIcon } from '../component/Atom/icons';
import { useState } from 'react';
import { useEffect } from 'react';
import cinstance from '../api/axios';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../helper/Query';
import { useSelector } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';



// const data = [
//     {
//         "id": "01",
//         "title": "Lorem ipsum dolor sit amet consectetur.",
//         "src": "/Images/Blog/post_two.png",
//         'href': '/practice-area/#resources',
//         "alt": "post_two ",
//         "content": "Lorem ipsum dolor sit amet consectetur. Ut id aliquam sem eu maecenas nibh cum phasellus. Nullam nulla interdum posuere."

//     },
//     {
//         "id": "02",
//         "title": "Lorem ipsum dolor sit amet consectetur.",
//         "src": "/Images/Blog/post_three.png",
//         'href': '/practice-area/#resources',
//         "alt": "post_three ",
//         "content": "Lorem ipsum dolor sit amet consectetur. Ut id aliquam sem eu maecenas nibh cum phasellus. Nullam nulla interdum posuere."

//     },
//     {
//         "id": "03",
//         "title": "Lorem ipsum dolor sit amet consectetur.",
//         "src": "/Images/Blog/post_four.png",
//         'href': '/practice-area/#resources',
//         "alt": "post_four ",
//         "content": "Lorem ipsum dolor sit amet consectetur. Ut id aliquam sem eu maecenas nibh cum phasellus. Nullam nulla interdum posuere."

//     },

// ]


const BlogView = () => {
    const [allBlogData, setAllBlogData] = useState(null);
    const [singleblog, setDSingleBlog] = useState()
    const [urlcopy, setUrlCopy] = useState("Copy")
    const language_web = useSelector((state) => state.webLanguage.value)
    const query = useQuery();


    const getBlogs = useCallback(async () => {

        try {
            await cinstance({
                url: `/blog/all-blog`,
                method: "GET",
            }).then((res) => {
                setAllBlogData([])
                res.data.map((value) => {
                    if (value.status == "active")
                        setAllBlogData((prev) => [...prev, value]);
                })
            });

        } catch (e) {
            console.error(e);
        }
    }, []);

    const getSingleBlogs = useCallback(async () => {
        try {
            await cinstance({
                url: `/blog/single-blog/${query.get("blog_id")}`,
                method: "GET",
            }).then((res) => {
                setDSingleBlog(res.data);
            });
        } catch (e) {
            console.error(e);
        }
    }, []);


    useEffect(() => {
        getBlogs();
        getSingleBlogs();
        return () => { };
    }, [query.get("blog_id")]);



    var d = new Date(singleblog?.createdAt);
    var date = d.getDate();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    var newDate = date + "/" + month + "/" + year;

    const navigate = useNavigate()

   // var trimmedString = content.substr(0, 150);
 
  // trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    
    //const paragraghfirst = singleblog?.[language_web]?.desc.split(".")[6]
    // const paragraghsecond = singleblog?.[language_web]?.desc.split(".")[7,0]
    //const paragraghsecond = singleblog?.[language_web]?.desc.slice(400, 800);
    const paragraghthird = singleblog?.[language_web]?.desc;
    
    //console.log(singleblog?.[language_web]?.desc)

    return (
        <UserLayout>
            <Breadcrumb image={'blog-banner'} />

            {/* label={language_web === "ARE" ? ("مدونة") : ("Blog")}  */}
            {/* <div className='bg-blog-banner'></div>  */}



            <div className='pt-6 xl:pt-[70px]'>
                <div className='container mx-auto xl:px-[250px] '>
                    <div className='flex-col justify-center items-center'>
                        <h1 className='text-lg lg:text-2xl xl:text-4xl font-semibold text-center  text-white'>{singleblog?.[language_web]?.title}</h1>
  {/* <p className='text-[14px] leading-[24px] font-extralight text-white-wind pt-6 pb-10 text-start' dangerouslySetInnerHTML={{ __html: paragraghfirst}} /> */}
                      
                    </div>

                    {/***Share components with Profile */}
                    {/* <div className='py-4'>
                        <div className='bg-true-gray-700 w-auto p-[0.5px]' />
                        <div className='flex justify-between items-center'>
                            <div className='py-8'>
                                <div className='flex items-center'>
                                    <img src={singleblog?.author?.img ? singleblog?.author?.img : 'https://st2.depositphotos.com/4111759/12123/v/950/depositphotos_121232092-stock-illustration-male-default-avatar-profile-gray.jpg'} alt="profile" className='w-10 h-10 lg:w-24 lg:h-24 rounded-full object-cover' />
                                    <div className='grid pl-8'>

                                        <h1 className='text-sm font-extralight text-white-wind'>{singleblog?.author?.name ? singleblog?.author?.name : "Author"}</h1>
                                        <div className='text-sm font-extralight text-white-wind'>Published on : {newDate}</div>
                                    </div>
                                </div>

                            </div>
                            <div className='px-8 py-16'>
                                <div className='flex gap-4 justify-between '>
                                    <div className='text-sm font-extralight text-white-wind'>Share :</div>
                                    <div className='flex gap-4'>
                                        <a href='https://web.whatsapp.com/'><img src='\whatsapp.png' className='h-6 w-6 object-cover' /></a>
                                        <a href='https://www.linkedin.com/company/bin-haider-advocates-&-legal-consultants/'><img src='\LinkedIn.png' className='h-6 w-6 object-cover' /></a>
                                        </div>


                                </div>
                                <div className='flex gap-2 justify-center items-center pt-4'>
                                    <div className='w-40 h-6 overflow-x-auto overflow-y-hidden border border-white scrollbar-hide text-white '>{window.location.href}</div>
                                    <CopyToClipboard
                                        text={window.location.href}
                                        onCopy={() => setUrlCopy("Copied")}>
                                        <span className='text-white text-xs bg-primary-wind font-extralight px-2 py-1'>{urlcopy}</span>
                                    </CopyToClipboard>

                                </div>
                            </div>
                        </div>

                        <div className='bg-true-gray-700 w-auto p-[0.5px] ' />
                    </div> */}


                    {/***Second Paragraph */}
                     {/* <div className='flex-col justify-center items-center'>

                        <p className='text-[14px] leading-[24px] font-extralight text-white-wind  pb-10 text-start' dangerouslySetInnerHTML={{ __html: paragraghsecond }} />
                    </div>  */}
                </div>


                {/***Image Component */}

                <div className='container mx-auto py-4 '>
                    {/* singleblog?.author?.img ? singleblog?.author?.img : */}
                    <img src={singleblog ? singleblog?.[language_web]?.img : 'image not found'} alt='post_seven' className='w-auto h-auto xl::w-[1170px] xl:h-[604px] object-contain mx-auto opacity-60' />
                </div>

                {/***After image Paragraph */}
                <div className='container mx-auto px-2 sm:px-0 xl:px-[250px] '>

                    <p className='text-[14px] leading-[24px] font-extralight text-white-wind pt-6 pb-10 text-start' dangerouslySetInnerHTML={{ __html: paragraghthird }} />
                    {/* {singleblog?.[language_web]?.desc} */}
                    {/* In lacinia sapien a libero accumsan facilisis. Donec vitae lorem massa. Aliquam tristique vehicula enim ut luctus. Vivamus gravida dignissim ligula, dictum laoreet elit malesuada ac. Praesent est justo, posuere a nisl porta, pharetra posuere lectus. Nulla velit odio, tincidunt vel metus a, viverra placerat ligula.

                        Donec id nisl et risus volutpat tempor a eget mauris. Nullam velit eros, porttitor et urna sit amet, ullamcorper vestibulum magna. Quisque consequat arcu eros, lobortis faucibus purus facilisis vitae. Nulla at nunc non purus vehicula elementum. */}
                    {/* </p>  */}

                    {/***Quotes */}
                    {/* <div className='bg-true-gray-700 '>
                        <div className='px-8 py-4'>
                            <div className='flex'><QuotesUpperIcon /></div>

                            <h1 className='text-2xl font-extralight text-center text-white-wind'>Suspendisse sagittis id lacus eget vulputate. Ut nec arcu ut sem molestie tincidunt luctus eget tellus.</h1>
                            <h4 className='text-sm text-white-wind text-center'>Parsley Montana</h4>
                            <div className='flex'>
                                <div className='flex-1' />
                                <QuotesLowerIcon />
                            </div>

                        </div>
                    </div>  */}

                    {/****Additional Content */}

                    {/* <div>
                        <h1 className='text-4xl font-semibold text-center text-white pt-6'>Big heading for a new topic</h1>
                        <p className='text-sm font-extralight text-white-wind pt-6  pb-10'>
                            Morbi pellentesque finibus libero, in blandit lorem eleifend eget. Praesent egestas hendrerit augue a vestibulum. Nullam fringilla, eros malesuada eleifend placerat, lacus tellus egestas erat, nec varius sem lorem ut mauris. Morbi libero felis.
                        </p>
                        <ul className="text-sm font-extralight text-white-wind ">
                            <li>Morbi pellentesque finibus libero, in blandit lorem eleifend eget. Praesent egestas hendrerit augue a vestibulum. Nullam fringilla, eros malesuada eleifend placerat, lacus tellus egestas erat, nec varius sem lorem ut mauris. Morbi libero felis.</li>
                            <li>Cras eget dolor accumsan, blandit risus vitae, faucibus erat. Aliquam scelerisque, diam ut feugiat scelerisque, diam felis venenatis purus, eget cursus enim turpis at sem. Fusce nec tristique dolor, sit amet tristique purus.</li>
                        </ul>
                    </div>  */}



                </div>



                {/***Share components with Profile */}
                <div className='pt-6 '>
                    <div className='container mx-auto xl:px-[250px] pr-6 sm:pl-0  '>
                        <div className='py-4'>
                            <div className='bg-true-gray-700 w-auto p-[0.5px]' />
                            <div className='flex justify-between items-center'>
                                <div className='py-8 pl-2 sm:pl-0'>
                                    <div className='flex items-center'>
                                        <img src={singleblog?.author?.img ? singleblog?.author?.img : 'https://st2.depositphotos.com/4111759/12123/v/950/depositphotos_121232092-stock-illustration-male-default-avatar-profile-gray.jpg'} alt="profile" className='w-10 h-10 lg:w-24 lg:h-24 rounded-full object-cover' />
                                        <div className='grid pl-8'>

                                            <h1 className='text-sm font-extralight text-white-wind'>{singleblog?.author?.name ? singleblog?.author?.name : "Author"}</h1>
                                            <div className='text-sm font-extralight text-white-wind px-2'>
                                            {language_web === "ARE" ?("نشرت في"):("Published on")} : {newDate}</div>
                                        </div>
                                    </div>

                                </div>
                                <div className='px-8 py-16'>
                                    <div className='flex gap-4 justify-between '>
                                        <div className=' text-xs sm:text-sm font-extralight text-white-wind px-2'>
                                            {language_web === "ARE" ?("يشارك"):("Share")} :</div>
                                        <div className='flex gap-4'>
                                            <a href='https://web.whatsapp.com/'><img src='\whatsapp.png' className='h-6 w-6 object-cover' /></a>
                                            <a href='https://www.linkedin.com/company/bin-haider-advocates-&-legal-consultants/'><img src='\LinkedIn.png' className='h-6 w-6 object-cover' /></a>

                                            {/* <a href='https://www.linkedin.com/'>
                                            <LinkinkedIcons />
                                        </a>
                                        <a href='https://web.whatsapp.com/'> <WhatsappIcon /></a>
                                         */}
                                        </div>


                                    </div>
                                    <div className='flex gap-2 justify-center items-center pt-4'>
                                        <div className='w-40 h-6 overflow-x-auto overflow-y-hidden border border-white scrollbar-hide text-white '>{window.location.href}</div>
                                        <CopyToClipboard
                                            text={window.location.href}
                                            onCopy={() => setUrlCopy("Copied")}>
                                            <span className='text-white text-xs bg-primary-wind font-extralight px-2 py-1'>{urlcopy}</span>
                                        </CopyToClipboard>

                                    </div>
                                </div>
                            </div>

                            <div className='bg-true-gray-700 w-auto p-[0.5px] ' />
                        </div>

                    </div>
                </div>


                {/* <div className='container mx-auto py-12'>
                    <div className='bg-true-gray-700 w-auto p-[0.5px] ' />
                </div> */}
            </div>





            <div className='container mx-auto pb-20 '>
                <div className='flex flex-wrap justify-between items-center'>
                    <h1 className='font-semibold text-white-wind text-4xl py-8 pl-2 sm:pl-0'>{language_web === "ARE" ? ("مدونة") : ("Blog")}</h1>

                    {/* <PrimaryButton onclick={() => window.history.back()} label={'Back'} /> */}
                </div>

                <div className='flex flex-wrap lg:justify-between justify-center gap-y-6  items-center'>

                    {allBlogData?.slice(0, 3).map((value) => {
                        return (
                            <div key={value._id} className='transform transition duration-500 hover:scale-105'>

                                <BlogCard
                                    onclick={() => { navigate(`/blog-view?title=${value?.[language_web]?.title}&blog_id=${value._id}`); window.location.reload(); }}
                                    // onclick={() => navigate(`/blog-view?blog_id=${value._id}`)}
                                    src={value?.[language_web]?.img}
                                    alt={value.alt}
                                    title={language_web === "ARE" ?("مدونة"):("Blog")}
                                    date={value?.createdAt}
                                    heading={value?.[language_web]?.title}
                                    description={value?.[language_web]?.desc}
                                />

                            </div>
                        )
                    })}


                </div>



            </div>


        </UserLayout>
    )
}

export default BlogView;