/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import cinstance from "../api/axios";
import {
  InputField,
  PrimaryButton,
  Select,
  TextField,
} from "./Atom/form-field";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import DateTimeCalendar from "./Calendar";
import { useCallback } from "react";
import moment from "moment";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import Modal from "react-modal";
import { useQuery } from "../api/query.js";

const time = [
  { name: "08:00 - 08:30" },
  { name: "08:30 - 09:00" },
  { name: "09:00 - 09:30" },
  { name: "09:30 - 10:00" },
  { name: "10:00 - 10:30" },
  { name: "10:30 - 11:00" },
  { name: "11:00 - 11:30" },
  { name: "11:30 - 12:00" },
  { name: "12:00 - 12:30" },
  { name: "12:30 - 01:00" },
  { name: "13:00 - 13:30" },
  { name: "13:30 - 14:00" },
  { name: "14:00 - 14:30" },
  { name: "14:30 - 15:00" },
  { name: "15:00 - 15:30" },
  { name: "15:30 - 16:00" },
];
export const ConsultationWithoutemail = () => {
  const [localdate, setLocalDate] = useState();
  const [localtime, setLocalTime] = useState("Choose Time");
  const query = useQuery();

  const [consultantmenu, setConsultantMenu] = useState(null);
  const [newclientdata, setNewClientData] = useState({
    message: "",
    slotdateAndTime: "",
    consultant: "",
    expertise: "",
    totalpaidmoney: ""
  });

  const [formErrors, setFormErrors] = useState({});

  const [oldClientData, setOldClientData] = useState({
    caseID: "",
    message: "",
  });

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCVV] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle payment submission here
  };

  const [about, setAbout] = useState(true);
  const [consultation, setConsultation] = useState(true);
  const [pay, setpayment] = useState(false);

//   const newClientset = () => {
//     setConsultation(true);
//     setpayment(false);
//   };

  const [client, setClient] = useState(true);
  const [oldclient, setOldClient] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

 

  const newClienthandler = () => {
    setClient(true);
    setOldClient(false);
  };

  const oldClienthandler = () => {
    setClient(false);
    setOldClient(true);
  };

  // const aboutHandler = () => {
  //     setAbout(true);
  //     setConsultation(false);
  // };
//   const openModal = () => {
//     setIsOpen(true);
//   };

//   const closeModal = () => {
//     setIsOpen(false);
//   };

  const payHandler = () => {
    if (
      !(
        localdate &&
        localtime &&
        newclientdata.expertise &&
        newclientdata.consultant &&
        newclientdata.message
      )
    ) {
        setpayment(false);
        setConsultation(true);
    }else{
      setpayment(true);
      setConsultation(false);

    }
  }

  const paymentHandler = async () => {
    return new Promise((resolve, reject) => {
      if (!(cardNumber && expiryDate && cvv)) {
        setSuccessMessage("Please fill in the payment details.");
        reject("Payment details are incomplete");
      } else {
        // Payment and consultation form are filled and validated
        setSuccessMessage("Payment successful!");
        const updatedPayBalance = 1500; // Updated paybalance value

        // Update the paybalance in newclientdata state
        setNewClientData((prevData) => ({
          ...prevData,
          totalpaidmoney: [
            ...prevData.totalpaidmoney,
            {
              amount: updatedPayBalance,
              message: "Paid money for this Case",
            },
          ],
        }));

        // Make an API call to save the updated data in your database
        saveDataToDatabase();

        resolve("Payment successful");
        console.log(updatedPayBalance, "Payment 1500");
        setTimeout(() => {
          window.location.reload();
      }, 2000)
      }
    });
  };

  const saveDataToDatabase = async () => {
    try {
      const response = await cinstance({
        url: `user/updateUserdata/${query.get("query_id")}`,
        method: "PUT",
        data: {
          totalpaidmoney: newclientdata.totalpaidmoney,
        },
      });

      if (response.status === 200) {
        console.log("Paybalance updated in the database.");
      } else {
        console.log("Failed to update paybalance in the database.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const today = new Date();
  let tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const Tomorrow = moment(tomorrow).format("DD-MM-YYYY");
  const SelectedDate = moment(localdate).format("DD-MM-YYYY");

  var date = new Date();

  var current_time = date.getHours() + ":" + date.getMinutes();

  const validate = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = "Name is required";
    }

    return errors;
  };

  useEffect(() => {
    // setFormErrors(validate(newclientdata))
    // console.log(formErrors, "nhewhf");
    //console.log(Object.keys(formErrors).length,"gdvgwv");
  }, [formErrors]);

  const consultationHandler = (e) => {
    e.preventDefault();
    let a = validate(newclientdata);

    setFormErrors(a);

    if (Object.keys(a).length == 0) {
      setAbout(false);
      setConsultation(true);
    } else {
      setAbout(true);
      setConsultation(false);
    }
  };

  const getConsultant = useCallback(async () => {
    try {
      await cinstance({
        url: `/today-active-consultant/get-all-status`,
        method: "GET",
      }).then((res) => {
        setConsultantMenu(res.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getConsultant();
    getPractices();
    return () => {};
  }, []);

  // {/***handle for New Client */ }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewClientData({ ...newclientdata, [name]: value });
  };

  const handleChangeMsg = (e) => {
    const { name, value } = e.target;
    setSuccessMessage("*Please Pay 1500rupees for this case");
    setNewClientData({ ...newclientdata, [name]: value });
  };

  // {/***Submit for New Client With Token*/ }
  const handleSubmitNewClient = async (e) => {
    e.preventDefault();
    try {
      const authToken = localStorage.getItem("token");
      if (authToken) {
        await cinstance({
          url: "/user/post-message",
          method: "POST",
          data: {
            ...newclientdata,
            slotdateAndTime:
              moment(localdate).format("DD-MM-YYYY") + " " + localtime,
          },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }).then((res) => {
          if (res.status === 200) {
            swal({
              title: "Thank you for reaching out to us ",
              text: "We will get back to you soon",
              icon: "success",
              buttons: "OK",
            });
            setTimeout(() => {
                navigate("/")

            //   window.location.reload();
            }, 3000);
          } else {
            swal({
              text: "Some thing went Wrong",
              icon: "warning",
              buttons: "OK",
            });
          }
        });
      } else {
        await cinstance({
          url: "/user/post-message",
          method: "POST",
          data: {
            ...newclientdata,
            slotdateAndTime:
              moment(localdate).format("DD-MM-YYYY") + " " + localtime,
          },
        }).then((res) => {
          if (res.status === 200) {
            swal({
              title: "Thank you for reaching out to us ",
              text: "We will get back to you soon",
              icon: "success",
              buttons: "OK",
            });
            setTimeout(() => {
                navigate("/")

            //   window.location.reload();
            }, 2000);
          } else {
            swal({
              text: "Some thing went Wrong",
              icon: "warning",
              buttons: "OK",
            });
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  // Fetch data without token ====================
  //     await cinstance({
  //         url: "/user/post-message",
  //         method: "POST",
  //         data: { ...newclientdata, slotdateAndTime: moment(localdate).format('DD-MM-YYYY') + " " + localtime },

  //     }).then((res) => {

  //         if (res.status === 200) {
  //             swal({
  //                 title: "Thank you for reaching out to us ",
  //                 text: "We will get back to you soon",
  //                 icon: "success",
  //                 buttons: "OK"
  //             })
  //             setTimeout(() => {
  //                 window.location.reload();
  //             }, 2000)
  //         }
  //         else {
  //             swal({
  //                 text: "Some thing went Wrong",
  //                 icon: "warning",
  //                 buttons: "OK"
  //             })
  //         }
  //     })

  // }

  const [allPracticesData, setAllPracticesData] = useState(null);
  const getPractices = useCallback(async () => {
    try {
      await cinstance({
        url: `/practice/all-practice`,
        method: "GET",
      }).then((res) => {
        setAllPracticesData(res.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  {
    /***handle for Old Client */
  }
  // const handleOldClientData = (e) => {
  //     let value = e.target.value;
  //     let name = e.target.name;

  //     setOldClientData((prevalue) => {
  //         return {
  //             ...prevalue,
  //             [name]: value
  //         }
  //     })
  // }

  const handleOldClientData = (e) => {
    const { name, value } = e.target;
    setNewClientData({ ...newclientdata, [name]: value });
  };

  {
    /***Submit for Old Client */
  }
  const handleOldClientDataSubmit = async (e) => {
    e.preventDefault();
    try {
      await cinstance({
        url: "/existing-user/post-message",
        method: "POST",
        data: oldClientData,
      }).then((res) => {
        if (res.status === 200) {
          swal({
            title: "Thanks For Again Connnecting Us....",
            text: "We will Connect you Soon",
            icon: "success",
            buttons: "OK",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          swal({
            text: "Some thing went Wrong",
            icon: "warning",
            buttons: "OK",
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  // console.log(current_time, "ctime");

  //console.log(current_time === ma, "Time");

  // const currentTime = today.getHours() + ":" + today.getMinutes();
  // console.log( typeof currentTime);
  // console.log(currentTime <= "sfgr");
  // console.log(currentTime >= "10:00","ajhY");
  // console.log(Tomorrow, "tomorrow Date")
  // console.log(moment(today).format('DD-MM-YYYY'), "Current Date");
  // console.log(moment(localdate).format('DD-MM-YYYY'), "selected Date")
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: "0",
      zIndex: 12,
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#171717",
      marginTop: "20px",
      overflowY: "auto",
      maxHeight: "80vh",
    },
  };

  const CheckTime = (time) => {
    //console.log(time,"sdg");
    // 24-04-2023 11:08
    let t =
      Date.parse(moment(today).format("MM-DD-YYYY") + " " + current_time) <=
      Date.parse(moment(today).format("MM-DD-YYYY") + " " + time);
    return t;
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={true}
      //  onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div>
        <div className="px-16 py-8 space-y-8">
          {/***Existing Client */}
          <>
            <div className="">
              <div className="flex space-x-4 px-24">
                {client ? (
                  <div
                    onClick={newClienthandler}
                    className="border-b-[1px] text-primary-wind border-primary-wind text-sm font-semibold cursor-pointer"
                  >
                    New Cases
                  </div>
                ) : (
                  <div
                    onClick={newClienthandler}
                    className=" text-true-gray-400 text-sm font-semibold cursor-pointer"
                  >
                    New Cases
                  </div>
                )}

                {oldclient ? (
                  <div
                    onClick={oldClienthandler}
                    className="border-b-[1px] text-primary-wind border-primary-wind text-sm font-semibold cursor-pointer"
                  >
                    Existing Cases
                  </div>
                ) : (
                  <div
                    onClick={oldClienthandler}
                    className=" text-true-gray-400 text-sm font-semibold cursor-pointer"
                  >
                    Existing Cases
                  </div>
                )}
              </div>
            </div>
            <div className="bg-white w-auto p-[0.5px] " />

            {/***New Client */}

            {client && (
              <>
                <div className="text-[14px] leading-[24px] text-white-wind flex justify-between gap-x-6">
                 

                  <div className='space-x-2 '>
                                    {pay? (<>
                                        <span className={`bg-true-gray-700 text-true-gray-500 w-6 h-6 px-4 py-2 items-center`}>1</span>
                                        <span className='text-true-gray-500'>Get Consultation</span>
                                    </>) :

                                        (<>
                                            <span className={`bg-primary-wind w-6 h-6 px-4 py-2 items-center`}>1</span>
                                            <span className='text-white-wind '>Get Consultation</span>
                                        </>)}


                                </div>

                                <div className='space-x-2'>
                                    {consultation ? (<>
                                        <span className='bg-true-gray-700 text-true-gray-500 w-6 h-6 px-4 py-2'>2</span>
                                        <span className='text-true-gray-500'>Payment Form</span>
                                    </>) :
                                        (<>
                                            <span className='bg-primary-wind w-6 h-6 px-4 py-2'>2</span>
                                            <span className='text-white-wind'>Payment From</span>
                                        </>)}
                                </div>
                  {/***Paymentent option */}
                  {/* <div className='space-x-2'>
                                    <span className='bg-true-gray-700 text-true-gray-500 w-6 h-6 px-4 py-2'>3</span>
                                    <span className='text-true-gray-500'>Payment Info</span>
                                </div> */}
                </div>

                <form onSubmit={handleSubmitNewClient}>
                  {/* {error ? (<div className='text-red-500 text-sm font-extralight'>Email is Invalid</div>):null} */}
                  {/* {about &&
                                    <div className='grid grid-cols-1 gap-y-4'>
                                        <InputField value={newclientdata.name} type={'text'} name={'name'} onchange={handleChange} placeholder={'Full Name*'} required />
                                        {formErrors.name ? <span className='text-red-600 text-sm'>{formErrors.name}</span> : null}
                                        <InputField value={newclientdata.state} type={'text'} name={'state'} onchange={handleChange} placeholder={'State'} />
                                        <InputField value={newclientdata.address} type={'text'} name={'address'} onchange={handleChange} placeholder={'Address'} />
                                        <InputField value={newclientdata.country} type={'text'} name={'country'} onchange={handleChange} placeholder={'Country'} />
                                    </div>

                                } */}

                  {consultation && (
                    <div className="grid grid-cols-1 gap-y-4">
                      <div className="grid grid-cols-2 gap-x-2">
                        <div className="text-[14px] leading-[24px] font-medium text-true-gray-400">
                          <DateTimeCalendar
                            placeholder={"Choose Date*"}
                            selected={localdate}
                            initDate={(e) => setLocalDate(e)}
                            required={true}
                          />
                        </div>

                        <div>
                          {SelectedDate === Tomorrow ? (
                            <>
                              <Listbox
                                value={"timevalue"}
                                onChange={(e) => setLocalTime(e.name)}
                              >
                                <div className="relative">
                                  <Listbox.Button className="relative w-full bg-true-gray-700 text-true-gray-400 text-[14px] leading-[24px]  px-3 py-2.5">
                                    <span className="block truncate text-start">
                                      {localtime}
                                    </span>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options
                                      selected={true}
                                      className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-true-gray-700 text-true-gray-400 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                    >
                                      {time.map((value, index) => (
                                        <Listbox.Option
                                          key={index}
                                          className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                              active
                                                ? "bg-primary "
                                                : "text-true-gray-400"
                                            }`
                                          }
                                          value={value}
                                        >
                                          {({ newclientdata }) => (
                                            <>
                                              <span
                                                className={`block truncate ${
                                                  newclientdata
                                                    ? "font-medium"
                                                    : "font-normal"
                                                }`}
                                              >
                                                {value.name}
                                              </span>
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </Listbox>
                            </>
                          ) : (
                            <>
                              {current_time >= "15:31" ? (
                                <>
                                  <div className="px-3 py-2 bg-true-gray-700">
                                    <span className="text-red-500 text-[10px] sm:text-xs">
                                      Today's slot is unavailable
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Listbox
                                    value={"timevalue"}
                                    onChange={(e) => setLocalTime(e.name)}
                                  >
                                    <div className="relative">
                                      <Listbox.Button className="relative w-full bg-true-gray-700 text-true-gray-400 text-[14px] leading-[24px]  px-3 py-2.5">
                                        <span className="block truncate text-start">
                                          {localtime}
                                        </span>
                                      </Listbox.Button>
                                      <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                      >
                                        <Listbox.Options
                                          selected={true}
                                          className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-true-gray-700 text-true-gray-400 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                        >
                                          {time.map((value, index) => (
                                            <div>
                                              {CheckTime(
                                                value.name.split("-")[0]
                                              ) ? (
                                                <>
                                                  {" "}
                                                  <Listbox.Option
                                                    key={index}
                                                    className={({ active }) =>
                                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                        active
                                                          ? "bg-primary "
                                                          : "text-true-gray-400"
                                                      }`
                                                    }
                                                    value={value}
                                                  >
                                                    {({ newclientdata }) => (
                                                      <>
                                                        <span
                                                          className={`block truncate ${
                                                            newclientdata
                                                              ? "font-medium"
                                                              : "font-normal"
                                                          }`}
                                                        >
                                                          {value.name}
                                                        </span>
                                                      </>
                                                    )}
                                                  </Listbox.Option>
                                                </>
                                              ) : null}
                                            </div>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </Listbox>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>

                      {/* <DateTimeCalendar placeholder={"Choose Date"} selected={newclientdata?.slotdateAndTime}
                                             initDate={(e) => { setNewClientData({ ...newclientdata, slotdateAndTime: e }) }} /> */}

                      <Select
                        onchange={handleChange}
                        name="expertise"
                        value={newclientdata.expertise}
                        required
                      >
                        <option
                          value=""
                          className="my-6 text-[14px] leading-[36px] block w-full text-true-gray-400 p-2.5"
                          disabled
                          selected
                        >
                          Choose Expertise*
                        </option>

                        {allPracticesData?.map((value, index) => {
                          return value.status === "active" ? (
                            <option
                              key={index}
                              name={value?.en?.title}
                              value={value?.en?.title}
                            >
                              {value?.en?.title}
                            </option>
                          ) : (
                            <></>
                          );
                        })}
                      </Select>

                      <Select
                        onchange={handleChangeMsg}
                        name="consultant"
                        value={newclientdata.consultant}
                        required
                      >
                        <option value="" disabled selected>
                          Choose Consultant*
                        </option>

                        {consultantmenu?.map((value, index) => {
                          return value.todayActive === "active" ? (
                            <option
                              key={index}
                              name={value?.refId?.en?.name}
                              value={value?.refId?.en?.name}
                            >
                              {value?.refId?.en?.name}
                            </option>
                          ) : (
                            <></>
                          );
                        })}

                        <option name="ceo" value="ceo">
                          Mr. Isa Bin Haider
                        </option>
                      </Select>


                      {successMessage && (
                        <p style={{ color: "green" }}>{successMessage}</p>
                      )}

                      {/* <InputField value={newclientdata.slotdateAndTime} type={'datetime-local'}
                                            name={'slotdateAndTime'} onchange={handleChange} />  */}
                      <TextField
                        rows={"4"}
                        value={newclientdata.message}
                        name={"message"}
                        onchange={handleChange}
                        placeholder={"Message*"}
                        required
                      />
                    </div>
                  )}
                  

                  {pay && (
                    <div className="grid grid-cols-1 gap-y-4">
                      <h2 className="text-2xl font-semibold mb-4 text-white">
                        Payment Details
                      </h2>
                      <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                          <label
                            htmlFor="cardNumber"
                            className="block text-sm font-medium mb-1 text-white"
                          >
                            Card Number
                          </label>
                          <input
                            type="text"
                            id="cardNumber"
                            className="input"
                            pattern="/^[0-9]{13,16}$/"
                            maxLength="16"
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                            placeholder="1234 5678 9012 3456"
                            required
                          />
                        </div>
                        <div className="flex space-x-2">
                          <div className="w-1/2">
                            <label
                              htmlFor="expiryDate"
                              className="block text-sm font-medium mb-2 text-white"
                            >
                              Expiry Date
                            </label>
                            <input
                              type="text"
                              id="expiryDate"
                              className="input"
                              pattern="(0[1-9]|1[0-2])\/[0-9]{2}"
                              maxLength={5}
                              value={expiryDate}
                              onChange={(e) => setExpiryDate(e.target.value)}
                              placeholder="MM/YY"
                              required
                            />
                          </div>
                          <div className="cvv">
                            <label
                              htmlFor="cvv"
                              className="block text-sm font-medium mb-1 ml-14 mt-1/2 text-white"
                            >
                              CVV
                            </label>
                            <input
                              type="text"
                              id="cvv"
                              className="input inherit w-20 ml-14 mt-1"
                              pattern="[0-9]{3}"
                              maxLength="3"
                              value={cvv}
                              onChange={(e) => setCVV(e.target.value)}
                              placeholder="123"
                              required
                            />
                          </div>
                        </div>
                        {successMessage && (
                          <p style={{ color: "green" }}>{successMessage}</p>
                        )}
                        
                        {/* <button
                          type="submit"
                          onClick={payHandler}
                          className="btn btn-primary w-32  font-bold bg-blue-500 hover:bg-blue-600 hover:text-black text-white rounded ml-[200px]"
                      //  disabled={!consultationSectionCompleted}  >
                          >Pay Now
                        </button> */}

                        {/* {pay && (
                        <button
                        type="submit"
                        onClick={() =>  {
                        handleSubmitNewClient(); 
                        paymentHandler();
                        }}
                        className="btn btn-primary w-32  font-bold bg-blue-500 hover:bg-blue-600 hover:text-black text-white rounded ml-[200px]"
                      >
                        Submit
                      </button>
                        )} */}
                      </form>
                    </div>
                  )}
                  <div className="flex pt-4 gap-x-4">
                    {/* {about && <PrimaryButton onclick={consultationHandler} label={'Next'} />} */}

                    {consultation && (
                      <PrimaryButton onclick={payHandler} label={"Pay"} />
                    )}
                    {pay && (
                      <button
                        type="submit"
                        onClick={paymentHandler}
                        className="btn btn-primary w-32  font-bold bg-blue-500 hover:bg-blue-600 hover:text-black text-white rounded ml-[200px]"
                      >
                        Submit
                      </button>
                    )}

                    {/* onclick={handleSubmitNewClient} */}
                  </div>
                </form>
              </>
            )}
          </>

          {oldclient && (
            <>
              <form onSubmit={handleOldClientDataSubmit}>
                <div className="grid grid-cols-1 gap-y-4">
                  <InputField
                    value={newclientdata.caseID}
                    type={"text"}
                    name={"caseID"}
                    onchange={handleOldClientData}
                    placeholder={"Please Share Your Current Case ID*"}
                    required
                  />
                  {/* <InputField type={'email'} name={"email"} placeholder={'Email'} /> */}
                  <TextField
                    rows={"4"}
                    name={"message"}
                    onchange={handleOldClientData}
                    placeholder={"Message*"}
                    required
                  />
                  <div className="pt-4">
                    <PrimaryButton label={"Submit"} />
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};