import React, { useCallback, useEffect, useState } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cinstance from '../api/axios';
import { CarouselCard } from '../component/Breadcrumb';
import { CardSlider, MultipleSlider } from '../component/MultiSlider/MultipleSlider';
import { UserLayout } from '../Layout/UserLayout'
import { HomeData } from '../helper/Static data/home';




// const data = [
//   {
//     "id": "01",
//     "title": " Energy, mining, commodities ",
//     "src": "/Images/home/resource.png",
//     'href': '/practice-area/#resources',
//     "alt": "resources ",
//     "content": "We advise regulators, the regulated, investors and other..."

//   },
//   {
//     "id": "02",
//     "title": "Healthcare",
//     "src": "/Images/home/health.png",
//     'href': '/practice-area/#healthcare',
//     "alt": "healthcare ",
//     "content": "The healthcare industry is one of the highly regulated industries regionally and globally, clients active in..."

//   },
//   {
//     "id": "03",
//     "title": "Hospitality",
//     "src": "/Images/home/hospitailty.png",
//     'href': '/practice-area/#hospitality',
//     "alt": "hospitality ",
//     "content": "We understand the legal issues pertaining to hospitality and the legal complexities arising from ..."

//   },
//   {
//     "id": "04",
//     "title": "Insurance",
//     "src": "/Images/home/Insurance.png",
//     "alt": "insurance ",
//     "content": "We offer a full range of insurance and re-insurance legal support services including transactional ..."

//   },
//   {
//     "id": "05",
//     "title": "maritime,logistics & shipping",
//     "src": "/Images/home/maritime.png",
//     "alt": "maritime ",
//     "content": "Our team has advised a range of regional and international clients"

//   },
//   {
//     "id": "06",
//     "title": "Education",
//     "src": "/Images/home/education.png",
//     "alt": "education",
//     "content": "Our clients include education companies, academic institutions, investors and technology ..."

//   },
// ]

const Home = () => {
  const [allTeamData, setAllTeamData] = useState(null);
  const [CMSHomeData, setCMSHomeData] = useState(null)
  const [allPracticesData, setAllPracticesData] = useState(null);
  // const [certification, setCertification] = useState()
  const [globaldata, setGlobalData] = useState()

  const language_web = useSelector((state) => state.webLanguage.value)

  // const getCerticationData = useCallback(async () => {
  //   try {
  //     await cinstance({
  //       url: "/certificate/all-certificates",
  //       method: "GET",
  //     }).then((res) => {

  //       setCertification(res.data)
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }, []);

  // useEffect(() => {
  //   getCerticationData()

  //   return () => {

  //   }
  // }, [])

  const getGlobalData = useCallback(async () => {
    try {
      await cinstance({
        url: "/page-intro/find/63e393105a0223b065c4b045",
        method: "GET",
      }).then((res) => {
        setGlobalData(res.data)
      });
    } catch (e) {
      console.error(e);
    }
  }, []);



  const getCMSHomeData = useCallback(async () => {
    try {
      await cinstance({
        url: "/cmsaboutcontent/get-about-content",
        method: "GET",
      }).then((res) => {
        setCMSHomeData(res.data)
      });
    } catch (e) {
      console.error(e);
    }
  }, []);



  const getPractices = useCallback(async () => {
    try {
      await cinstance({
        url: `/practice/all-practice`,
        method: "GET",
      }).then((res) => {
        setAllPracticesData(res.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);



  const getTeams = useCallback(async () => {
    try {
      await cinstance({
        url: `/ourteams/get-all-teams/`,
        method: "GET",
      }).then((res) => {
        setAllTeamData(res.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getGlobalData();
    getPractices();
    getCMSHomeData()
    getTeams();

    return () => { };
  }, []);



  const navigate = useNavigate()

 

  return (
    <UserLayout>

      <CarouselCard />

      {/***About CEO */}

      {/* <div className='flex flex-wrap justify-between pt-10'> */}


      <div className='h-auto w-screen'>
        <div className='relative z-10 '>
          <div className='relative xl:absolute inset-0'>
            <div className='container mx-auto'>
              <div className='grid grid-cols-1 xl:grid-cols-2 justify-between  gap-6'>
              
                  <div className='text-white xl:pt-[40px] xl:py-[40px] p-6 xl:p-0 '>
                    {language_web === "ARE" ? (<h1 className={`uppercase font-semibold text-2xl xl:text-4xl pb-6 `}>كلمة الرئيس التنفيذي</h1>)
                      : (<h1 className={` font-semibold text-2xl xl:text-4xl pb-6 `}>CEO's MESSAGE</h1>)}

                    <p className='font-extralight text-sm xl:text-sm  text-start lg:w-[50%] xl:w-auto '
                      dangerouslySetInnerHTML={{ __html: CMSHomeData?.CEO?.[language_web]?.message }} />
                    <div className='flex gap-4 pt-6'>
                      <div className='bg-[#00609C] p-[1px] w-1 h-[48px]' />
                      {language_web === "ARE" ? (
                        <div>
                          <h1 className='text-xl xl:text-2xl font-semibold  text-[#00609C]'>السيد عيسى بن حيدر</h1>
                          <h2 className='text-sm xl:text-lg font-extralight '>الرئيس التنفيذي ومؤسس بن حيدر</h2>
                        </div>
                      ) : (
                        <div>
                          <h1 className='text-xl xl:text-2xl font-semibold  text-[#00609C]'>Mr. ISA BIN HAIDER</h1>
                          <h2 className='text-[14px] leading-[24px] font-extralight  '>CEO & Founder of Bin Haider</h2>
                        </div>
                      )}

                    </div>
                  </div>
             

              </div>

            </div>

          </div>

          <div className='xl:flex flex-wrap flex-none '>
            <div className='bg-true-gray-800 text-white xl:h-[455px] w-[50%]'></div>
            <div className='bg-transparent h-auto w-auto xl:w-[50%]'>
              <img src={CMSHomeData?.CEO?.img} alt='ceo_image' className="w-[100%] h-auto xl:h-[455px] object-cover" />
            </div>
          </div>
        </div>
      </div>

      {/* <div className='flex flex-col-reverse xl:flex-row lg:flex-col '>
        <div className='bg-true-gray-800 text-white w-auto xl:h-[455px]'>

          <div className='container mx-auto p-4 xl:py-[30px] xl:pl-[46px] 2xl:pl-[192px] 2xl:pr-[160px]'>
            <div className='xl:py-[40px]'>
              {language_web === "ARE" ? (<h1 className={`uppercase font-semibold text-2xl xl:text-4xl pb-6 `}>كلمة الرئيس التنفيذي</h1>)
                : (<h1 className={` font-semibold text-2xl xl:text-4xl  pb-6 `}>CEO's MESSAGE</h1>)}

              <p className='font-extralight text-sm xl:text-sm leading-8 text-start'
                dangerouslySetInnerHTML={{ __html: CMSHomeData?.CEO?.[language_web]?.message }} />
              <div className='flex gap-4 pt-6'>
                <div className='bg-[#00609C] p-[1px] w-1 h-[48px]' />
                {language_web === "ARE" ? (
                  <div>
                    <h1 className='text-xl xl:text-2xl font-semibold  text-[#00609C]'>عيسى بن حيدر</h1>
                    <h2 className='text-sm xl:text-lg font-extralight  '>الرئيس التنفيذي</h2>
                  </div>
                ) : (
                  <div>
                    <h1 className='text-xl xl:text-2xl font-semibold  text-[#00609C]'>ISA BIN HAIDER</h1>
                    <h2 className='text-sm xl:text-lg font-extralight  '>CEO & Founder of Bin Haider</h2>
                  </div>
                )}

              </div>
            </div>
          </div>

        </div>
        <img src={CMSHomeData?.CEO?.img} alt='ceo_image' className="w-auto h-auto xl:h-[455px] object-contain" />
      </div> */}


      {/**Speciaised practice Area */}  


      <div>
        <div className="container mx-auto pt-[50px] sm:pt-[60px] xl:pt-[70px]">
          {language_web === "ARE" ? (<div className='pb-8 xl:pb-14 text-white'>
            <h3 className={`font-extralight text-lg xl:text-2xl uppercase text-center  xl:py-2 `}>ما نحن خبراء فيه</h3>
            <h1 className='text-2xl xl:text-4xl font-semibold  text-center  xl:py-2'>مجالات الممارسة المتخصصة</h1>
          </div>) : (<div className='pb-8 xl:pb-14 text-white'>
            <h3 className={`font-extralight text-lg xl:text-2xl uppercase text-center  xl:py-2 `}>What we Are Expert At</h3>
            <h1 className='text-2xl xl:text-4xl font-semibold  text-center  xl:py-2'>Specialised Practice Areas</h1>
          </div>)}

        </div>


        <div className='container mx-auto pl-[20px] xl:px-4'>
          <MultipleSlider>
            {allPracticesData?.map((value,index) => (
              value?.status === "active" ?( 
              <CardSlider 
              key={index} 
              src={value?.en?.img} 
              alt={value.alt} 
              title={value?.[language_web]?.title}
              content={value?.[language_web]?.desc}
              path={() => navigate("/practice-area")}
              />):null
             
            ))}
          </MultipleSlider>

        </div>


        {/* <div className='container mx-auto'>
          <div className='flex flex-wrap justify-between  gap-y-[56px] '>
            <>
              {data.map((value) => {
                return (
                  <div key={value.id} className='transform transition duration-500 hover:scale-125 '>
                    <div className='w-[460px]  object-cover'>
                      <img src={value.src} alt={value.alt} className="w-[460px] h-auto object-cover" />
                      <div className=' w-auto h-auto'>
                        <h3 className='text-2xl font-semibold text-black text-start break-words uppercase pt-4'>{value.title}</h3>
                        <p className='text-sm font-extralight text-true-gray-600 text-start pt-2 pb-8'>{value.content}</p>
                        <a href={value.href}><label className='text-sm font-medium text-blue-500 text-start cursor-pointer'>Read More</label></a>
                      </div>
                    </div>
                  </div>
                )
              })}

            </>
          </div>
        </div>  */}

      </div>






      {/***Team */}


      {/* <div className='bg-primary w-screen'>
        <div className='container mx-auto '>

          <div className='grid grid-cols-2 items-center gap-12'>

            <div>
              <h1 className='text-2xl xl:text-4xl font-semibold '>Our Team</h1>
              <p className='text-sm xl:text-sm font-extralight pb-8 '>
                We are incredibly proud to boast a team of diverse, accomplished and experienced lawyers, legal consultants and legal researchers. Every member of our team has experience covering a range of legal topic areas, as well as a specialised area of practice, allowing us to provide unrivalled experience and expertise to our clients to ensure the best possible results.
                <br /><br />
                Our commitment to our clients is total. Our Client Relations Department works tirelessly to ensure that all of our clients are regularly updated with the status of their case in addition to enjoying an unparalleled level of service.
              </p>
              <button onClick={() => navigate('/our-team')} className='text-sm font-medium  text-primary-wind px-6 py-3 bg-white'>View All Team</button>
            </div>

          
              <div className=''>
                <div className='flex flex-wrap justify-center md:gap-6 xl:gap-0 xl:justify-between items-center'>
                  <div className='flex flex-col gap-y-4'>

                    <div className='transform transition duration-700 hover:scale-105'>
                      <img src='\Images\home\Dr_Abdullah_Saeed.png' alt='Dr_Abdullah_Saeed' className='object-cover w-[240px] height-[240px]' />
                      <div className='text-white'>
                        <h2 className='text-2xl font-semibold  '>Dr Abdullah Banmah</h2>
                        <label className='text-sm font-extralight '>Lawyer</label>
                      </div>

                    </div>


                    <div className=' transform transition duration-700 hover:scale-105'>
                      <img src='\Images\home\Salem_bin_Kowairb.png' alt='Salem_bin_Kowairb' className=' object-cover w-[240px] height-[240px]' />
                      <div className='text-white'>
                        <h2 className='text-2xl font-semibold '>Mr. Salem Binkowair</h2>
                        <label className='text-sm font-extralight'>Lawyer</label>
                      </div>

                    </div>




                  </div>

                  <div className='relative '>

                    <div className='relative z-10 flex flex-col gap-y-4'>
                      <div className='transform transition duration-500 hover:scale-105'>

                        <img src='\Images\home\Abdulla_AI_Raisi.png' alt='Abdulla_AI_Raisi' className='object-cover w-[240px] height-[240px]' />
                        <div className='text-white'>
                          <h2 className='text-2xl font-semibold '>Mr.Abdullah al Raise</h2>
                          <label className='text-sm font-extralight '>Lawyer</label>
                        </div>
                      </div>

                      <div className='transform transition duration-500 hover:scale-105 '>

                        <img src='\Images\home\Mona_Ablooshi.png' alt='Mona_Ablooshi' className='object-cover w-[240px] height-[240px]' />
                        <div className='text-white-wind'>
                          <h2 className='text-2xl font-semibold'> Mrs. Muna Alblosshi</h2>
                          <label className='text-sm font-extralight '>Lawyer</label>
                        </div>


                      </div>
                    </div>


                    <div className='absolute right-[-66px]  top-[150px]'>
                      <img src='\Images\home\dotbackground.svg' alt='dotimage' className='w-auto h-auto ' />
                    </div>

                  </div>

                  <div>






                  </div>
                </div>



              </div>
            



          </div>

        </div>
      </div> */}


      <div className='py-[70px] xl:h-[802px] w-auto'>
        <div className='xl:relative z-10 '>
          <div className='xl:absolute inset-0 '>
            <div className='container mx-auto'>
              <div className='grid grid-cols-1 xl:grid-cols-2 xl:justify-between  items-center xl:gap-x-24'>
                <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInLeft">
                  <div className='text-white bg-primary xl:bg-transparent p-6 lg:p-4 xl:p-0'>
                    <h1 className=' text-2xl xl:text-4xl font-semibold pb-4'> {language_web === "ARE" ? ("فريقنا") : ("Our Team")}</h1>
                    <p className='text-[14px] leading-[24px] font-extralight pb-8 text-start ' dangerouslySetInnerHTML={{ __html: globaldata?.[language_web]?.ourteam }} />
                    <button onClick={() => navigate('/our-team')} className='text-sm font-medium  text-primary-wind px-6 py-3 bg-white'>
                    {language_web === "ARE" ? ("الفريق"):("View All Team")}
                    </button>
                  </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInRight">
                  <div className='relative z-10 grid grid-cols-1 sm:grid-cols-2 xl:justify-between justify-items-center justify-center items-center gap-6'>
                    <div className='absolute  right-[-30px] lg:right-[-50px] top-[150px]'>
                      <img src='\Images\home\dotbackground.svg' alt='dotimage' className='w-auto h-auto ' />
                    </div>
                    {allTeamData?.map((value) => {
                      return (
                        value?.featured === true ? (
                          value?.isActive === "active" ? (
                            value.en?.position === "Lawyer" ? (
                              <div onClick={() => navigate(`/our-team-view?title=${value?.[language_web]?.name}&team_id=${value._id}`)} 
                              className='transform transition duration-700 hover:scale-105 pt-8 xl:pt-0 cursor-pointer  '>

                                <img src={value?.img} alt={value.alt} className='object-contain w-[265px] height-[265px]' />
                                <div className='text-white pt-2 w-[265px]'>
                                  <h2 className='text-2xl font-semibold truncate'>{value?.[language_web]?.name}</h2>
                                  <label className='text-[14px] leading-[24px] font-extralight'>
                                    {language_web === "ARE" ? ("المحامي") : (<span>{value.en?.position}</span>)}</label>
                                </div>

                              </div>) : <></>
                          ) : (<></>)
                        ) : (<></>)
                      )
                    })}
                  </div>
                </AnimationOnScroll>
              </div>

            </div>

          </div>

          <div className='xl:flex justify-between items-center gap-x-4 hidden xl:visible'>
            <div className='bg-primary h-[472px] mt-[76px] w-[50%]'></div>
            <div className='h-[580px] w-[50%]'></div>
          </div>
        </div>
      </div>



      {/* <div className='py-[70px] h-auto w-auto'>

        <div className='grid grid-cols-1 xl:grid-cols-2 items-center  gap-6 '>

          <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInLeft">
            <div className='bg-primary-wind text-white-wind'>
              <div className='container mx-auto p-4 2xl:py-[70px] xl:pl-[56px] 2xl:pl-[210px] 2xl:pr-[30px]'>
                <h1 className=' text-2xl xl:text-4xl font-semibold pb-4'> {language_web === "ARE" ? ("فريقنا") : ("Our Team")}</h1>
                <p className='text-sm xl:text-sm font-extralight pb-8 text-start' dangerouslySetInnerHTML={{ __html: globaldata?.[language_web]?.ourteam }} />
                <button onClick={() => navigate('/our-team')} className='text-sm font-medium  text-primary-wind px-6 py-3 bg-white'>View All Team</button>
              </div>
            </div>
          </AnimationOnScroll>

          <div className='mx-auto'>


            <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInRight">
              <div className='relative z-10 grid grid-cols-1 sm:grid-cols-2 justify-between items-center gap-6'>
                <div className='absolute  right-[-50px] top-[150px]'>
                  <img src='\Images\home\dotbackground.svg' alt='dotimage' className='w-auto h-auto ' />
                </div>
                {allTeamData?.map((value) => {
                  return (
                    value?.featured === true ? (
                      value?.isActive === "active" ? (
                        value.en?.position === "Lawyer" ? (
                          <div className='transform transition duration-700 hover:scale-105'>

                            <img src={value?.img} alt={value.alt} className='object-cover w-[265px] height-[265px]' />
                            <div className='text-white pt-2 w-[265px]'>
                              <h2 className='text-2xl font-semibold truncate'>{value?.[language_web]?.name}</h2>
                              <label className='text-sm font-extralight '>{value.en?.position}</label>
                            </div>

                          </div>) : <></>
                      ) : (<></>)
                    ) : (<></>)
                  )


                })}

           </div>
            </AnimationOnScroll>
 <div>
 </div>
</div>
</div>
</div> */}


      {/**Certification section */}

      {/* <div className='w-auto h-auto'>
        <div className='container mx-auto pb-16 xl:px-8 2xl:px-0'>
          <h1 className='text-2xl xl:text-4xl font-semibold text-white text-center pb-16'>Awards and Certifications</h1>
          <div className='flex flex-wrap justify-center xl:justify-between gap-6 xl:gap-6'>
            {certification?.map((value) => {
              return (
                value.status === "active" ? (

                  <div key={value._id} className='bg-true-gray-800'>
                    <a href={value?.link}>
                    <div className='p-8'>
                      <img src={value?.img} alt='award_one' />
                    </div>
                    </a>
                  </div>
                ) : (<></>)

              )
            })}

            {/* <div className='bg-true-gray-800'>
              <div className='p-8'>
                <img src='\Images\home\Award_one.png' alt='award_one' />
              </div>
            </div>
            <div className='bg-true-gray-800'>
              <div className='p-8'>
                <img src='\Images\home\Award_2.png' alt='award_one' />
              </div>
            </div>
            <div className='bg-true-gray-800'>
              <div className='p-8'>
                <img src='\Images\home\Award_3.png' alt='award_one' />
              </div>
            </div>
            <div className='bg-true-gray-800'>
              <div className='p-8'>
                <img src='\Images\home\Award_4.png' alt='award_one' />
              </div>
            </div> */}

      {/* <p className=' text-sm lg:text-2xl font-semibold text-white w-auto lg:w-[600px]'>Bin Haider advocates & legal consultants.</p>

            <button className='text-sm text-white border-2 border-white px-4 py-2'>Get Consultation</button> */}

      {/* </div>
        </div>
      </div> */}





    </UserLayout>
  )
}

export default Home;