import React, { useEffect, useState } from 'react'
import cinstance from '../api/axios';
import { InputField, PrimaryButton, Select, TextField } from './Atom/form-field';
import DateTimeCalendar from './Calendar';
import { useCallback } from 'react';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import swal from 'sweetalert'
import 'react-phone-input-2/lib/style.css';

export const Consultation = () => {

    const [localdate, setLocalDate] = useState()

    const [consultantmenu, setConsultantMenu] = useState(null)
    const [newclientdata, setNewClientData] = useState({
        name: "",
        email: "",
        mobileNumber: "",
        address: "",
        country: "",
        state: "",
        message: "",
        slotdateAndTime: "",
        consultant: "",
        expertise: ""
    })

    const [formErrors, setFormErrors] = useState({})

    const [oldClientData, setOldClientData] = useState({
        caseID: "",
        message: ""

    })

    const [about, setAbout] = useState(true);
    const [consultation, setConsultation] = useState(false);


    const aboutHandler = () => {
        setAbout(true);
        setConsultation(false);
    };


    const today = new Date();
    let tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)

    const validate = (values) => {


        let errors = {}

        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;


        if (!values.email) {
            errors.email = "Email id is required"
        }
        else if (!regex.test(values.email)) {
            errors.email = "Invalid Email"
        }
        if (!values.name) {
            errors.name = "Name is required"
        }
        if (!values.mobileNumber) {
            errors.mobileNumber = "Number is required"
        }
        return errors

    }

    const consultationHandler = (e) => {
        e.preventDefault();
        let a = validate(newclientdata);

        setFormErrors(a)

        if (Object.keys(a).length == 0) {
            setAbout(false);
            setConsultation(true)
        }

        else {
            setAbout(true);
            setConsultation(false);
        }


    }

    const getConsultant = useCallback(async () => {
        try {
            await cinstance({
                url: `/today-active-consultant/get-all-status`,
                method: "GET",
            }).then((res) => {
                setConsultantMenu(res.data);
            });
        } catch (e) {
            console.error(e);
        }
    }, []);


    useEffect(() => {
        getConsultant();
        getPractices()
        return () => { };
    }, []);


    {/***handle for New Client */ }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewClientData({ ...newclientdata, [name]: value });

    }

    {/***Submit for New Client */ }
    const handleSubmitNewClient = async (e) => {
        e.preventDefault();
        try {
            await cinstance({
                url: "/user/post-message",
                method: "POST",
                data: { ...newclientdata, slotdateAndTime: moment(localdate).format('DD-MM-YYYY')},
            }).then((res) => {
                if (res.status === 200) {
                    swal({
                        title: "Thank you for reaching out to us ",
                        text: "We will get back to you soon",
                        icon: "success",
                        buttons: "OK"
                    })
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000)
                }
                else {
                    swal({
                        text: "Something went Wrong",
                        icon: "warning",
                        buttons: "OK"
                    })
                }
            })
        } catch (e) {
            console.error(e);
        }
    }

    const [allPracticesData, setAllPracticesData] = useState(null)
    const getPractices = useCallback(async () => {
        try {
            await cinstance({
                url: `/practice/all-practice`,
                method: "GET",
            }).then((res) => {
                setAllPracticesData(res.data);
            });
        } catch (e) {
            console.error(e);
        }
    }, []);

    return (
        <div className='lg:w-[40vw] 2xl:w-[30vw]'>
            <div className='p-5 md:px-12 md:py-8 space-y-8'>
                <div className='text-[14px] leading-[24px] text-white-wind flex justify-between gap-x-6'>
                    <div className='space-x-2'>
                        {consultation ? (<>
                            <span className='bg-true-gray-700 text-true-gray-500 w-6 h-6 px-4 py-2'>1</span>
                            <span className='text-true-gray-500'>About You</span>
                        </>) :
                            (<>
                                <span className='bg-primary-wind w-6 h-6 px-4 py-2'>1</span>
                                <span className='text-white-wind'>About You</span>
                            </>)}
                    </div>

                    <div className='space-x-2 '>
                        {about ? (<>
                            <span className={`bg-true-gray-700 text-true-gray-500 w-6 h-6 px-4 py-2`}>2</span>
                            <span className='text-true-gray-500'>Get Consultation</span>
                        </>) :

                            (<>
                                <span className={`bg-primary-wind w-6 h-6 px-4 py-2`}>2</span>
                                <span className='text-white-wind '>Get Consultation</span>
                            </>)}
                    </div>
                </div>

                <form onSubmit={handleSubmitNewClient} >
                    {about &&
                        <div className='grid grid-cols-1 gap-y-4'>
                            <InputField value={newclientdata.name} type={'text'} name={'name'} onchange={handleChange} placeholder={'Full Name*'} required />
                            {formErrors.name ? <span className='text-red-600 text-sm'>{formErrors.name}</span> : null}

                            <InputField value={newclientdata.email} type={'email'} name={'email'} onchange={handleChange} placeholder={'Email Address*'} required />
                            {formErrors.email ? <span className='text-red-600 text-sm'>{formErrors.email}</span> : null}


                            <div className='grid grid-cols-1 gap-y-4'>
                                <PhoneInput
                                    country={'ae'}
                                    value={newclientdata.mobileNumber}
                                    onChange={(value) => setNewClientData({ ...newclientdata, mobileNumber: value })}
                                    inputProps={{
                                        name: 'mobileNumber',
                                        required: true,
                                        className: 'w-full p-2 pl-12 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray text-true-gray-400',
                                    }}
                                    dropdownStyle={{
                                        backgroundColor: 'gray',
                                    }}

                                    buttonStyle={{
                                        backgroundColor: 'gray',
                                        borderColor: 'gray'
                                    }}
                                />
                            </div>
                            {formErrors.mobileNumber ? <span className='text-red-600 text-sm'>{formErrors.mobileNumber}</span> : null}
                            <InputField value={newclientdata.state} type={'text'} name={'state'} onchange={handleChange} placeholder={'Emirates'} />
                            <InputField value={newclientdata.address} type={'text'} name={'address'} onchange={handleChange} placeholder={'Address'} />
                            <InputField value={newclientdata.country} type={'text'} name={'country'} onchange={handleChange} placeholder={'Country'} />
                        </div>

                    }

                    {consultation &&

                        <div className='grid grid-cols-1 gap-y-4'>
                            <div className='text-[14px] leading-[24px] font-medium text-true-gray-400'>
                                <DateTimeCalendar placeholder={"Choose Date*"} selected={localdate}
                                    initDate={(e) => setLocalDate(e)} required={true} />
                            </div>

                            <Select onchange={handleChange} name='expertise' value={newclientdata.expertise} required>
                                <option value="" className='my-6 text-[14px] leading-[36px] block w-full text-true-gray-400 p-2.5' disabled selected>Choose Expertise*</option>

                                {allPracticesData?.map((value, index) => {
                                    return (
                                        value.status === "active" ?
                                            (<option key={index} name={value?.en?.title} value={value?.en?.title}>
                                                {value?.en?.title}
                                            </option>) : (<></>)
                                    )
                                })}
                            </Select>

                            <Select onchange={handleChange} name='consultant' value={newclientdata.consultant} required>

                                <option value="" disabled selected >Choose Consultant*</option>

                                {consultantmenu?.map((value, index) => {
                                    return (
                                        value.todayActive === "active" ?
                                            (<option key={index} name={value?.refId?.en?.name} value={value?.refId?.en?.name}>
                                                {value?.refId?.en?.name}
                                            </option>) : (<></>)
                                    )
                                })}

                                <option name="ceo" value="ceo" >Mr. Isa Bin Haider</option>
                                <option name="other" value="other" >Other</option>
                            </Select>
                            <TextField rows={'4'} value={newclientdata.message} name={'message'} onchange={handleChange} placeholder={'Message*'} required />

                        </div>
                    }
                    <div className='flex pt-4 gap-x-4'>
                        {about && <PrimaryButton onclick={consultationHandler} label={'Next'} />}

                        {consultation && <PrimaryButton onclick={aboutHandler} label={'Back'} />}

                        {consultation && <PrimaryButton type={"submit"} label={'Submit'} />}

                    </div>
                </form>
            </div>
        </div>
    )
}
