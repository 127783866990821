import React from 'react';

export const PrimaryButton = ({ label, onclick }) => {
    return (
        <button
            onClick={onclick}
            className={`bg-primary-wind  px-5 py-2 sm:py-3 sm:px-8 xl:py-4 text-white text-xs md:text-sm whitespace-nowrap`}>
            {label}
        </button>
    )
}


export const SecondaryButton = ({ label, onclick }) => {
    return (
        <button
            onClick={onclick}
            className={`bg-white border-2 border-white px-4 py-2 xl:px-8 xl:py-4 text-primary text-sm`}>
            {label}
        </button>
    )
}


export const InputField = ({
    type,
    placeholder,
    id,
    required,
    maxLength,
    minLength,
    name,
    onchange,
    value,
    pattern
}) => {
  
    return (
        <div className='text-sm font-extralight '>
            <input
                style={{ backgroundColor: `rgba(255,255,255,0.18)` }}
                type={type}
                placeholder={placeholder}
                id={id}
                name={name}
                required={required}
                value={value}
                onChange={onchange}
                maxLength={maxLength}
                minLength={minLength}
                pattern={pattern}
                autocomplete="off"
                className={`block w-full p-2.5 text-[14px] leading-[24px] focus:dark-bg-true-gray-300 text-true-gray-400 appearance-none focus:ring-0 focus:bg-none focus:outline-none px-3 py-2.5 `} />
        </div>
    )
}

export const TextField = ({
    type,
    value,
    placeholder,
    id,
    name,
    pattern,
    onchange,
    minLength,
    maxlength,
    required
}) => {
    return (
        <div>
            <textarea
                style={{ backgroundColor: `rgba(255,255,255,0.18)` }}
                rows={6}
                type={type}
                placeholder={placeholder}
                id={id}
                maxLength={maxlength}
                minLength={minLength}
                name={name}
                value={value}
                onChange={onchange}
                pattern={pattern}
                required={required}
                className=' block w-full  h-auto focus:outline-none appearance-none focus:ring-0 text-[14px] leading-[24px]  text-true-gray-400 px-3 py-2.5' />

        </div>
    )
}

export const Select = ({
    required,
    name,
    value,
    id,
    children,
    onchange,
    label
}) => {
    return (
        <div className=''>
            {/* <label for="cars">Choose a car:</label> */}
            <label className='bg-true-gray-700 text-true-gray-400 text-sm '>
                {label}
            <select onChange={onchange} id={id} name={name} value={value} required={required}
            className="bg-true-gray-700  text-true-gray-400 text-[14px] leading-[24px] focus:ring-0 appearance-none focus:outline-none block w-full px-3 py-2.5">
                {children}

            </select>
            </label>
          
        </div>
    )
}

// export const Select = ({ values, onValueChange, selectedValue, ...rest }) => {
//     return (
//       <select className="bg-true-gray-700 text-true-gray-400 placeholder-gray-400 text-sm  focus:ring-0 appearance-none focus:outline-none block w-full px-3 py-2.5 space-y-3 "
//         defaultValue={selectedValue}
//         onChange={({onValueChange}
//         {...rest}
//       >
//         {values.map(([value, text]) => (
//           <option key={value} value={value}>
//             {text}
//           </option>
//         ))}
//       </select>
//     );
//   };