import React from 'react'
import { UserLayout } from '../Layout/UserLayout'
import { Breadcrumb } from "../component/Breadcrumb";
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useQuery } from '../helper/Query';
import { useState } from 'react';
import { useEffect } from 'react';
import cinstance from '../api/axios';
import { PrimaryButton } from '../component/Atom/form-field';
import { useNavigate } from 'react-router-dom';





const ClientOurTeamView = () => {


  const [singlemember, setSingleMember] = useState()

  const language_web = useSelector((state) => state.webLanguage.value)
  const query = useQuery();

  const getSingleMember = useCallback(async () => {
    try {
      await cinstance({
        url: `/ourteams/get-single-team/${query.get("team_id")}`,
        method: "GET",
      }).then((res) => {
        setSingleMember(res.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  let navigate = useNavigate();
  useEffect(() => {
    getSingleMember();
    return () => { };
  }, [query.get("team_id")]);


  const desc = singlemember?.[language_web]?.desc

  return (
    <UserLayout>
      <Breadcrumb image={"ourteam-banner"}  />
      {/* label={language_web === "ARE" ?("فريقنا"): ("Our Team")} */}

      <div className='container mx-auto sm:py-4  xl:py-[70px]'>
        <div>
          <div className='grid grid-cols-1 xl:grid-cols-2 justify-items-center xl:justify-items-start'>
            <img src={singlemember?.img} alt={""} className='h-[450px] sm:h-[570px] object-contain' />
              <div className='h-auto sm:pt-4 xl:pt-0  text-white-wind'>
              <div className='grid'>
                <div className='font-semibold text-lg text-start '>{singlemember?.[language_web]?.name}</div>
                <div className='pt-2'><span className='font-normal text-sm'> {language_web === "ARE" ?("تعيين"):("Designation")}:  </span>
                  <span className='text-[14px] leading-[24px] font-extralight'>{singlemember?.[language_web]?.position}</span> </div>
                <div className='pt-2' > <span className='font-normal text-sm'> {language_web === "ARE" ?("خبرة"):("Experience")}: </span>
                  <span className='text-[14px] leading-[24px] font-extralight'>{singlemember?.experienceyear}+</span></div>
              </div>
              <div>
                <h1 className='font-semibold text-2xl py-4'> {language_web === "ARE" ?("ملخص"):("Summary")}</h1>
                <p className='font-extralight text-[14px] leading-[24px] text-start fake-class' dangerouslySetInnerHTML={{ __html: desc }} />
              </div>
              {/***back Button */}
              <div className='py-6'>
                <button onClick={() => navigate("/our-team")} className="bg-primary-wind  px-3 py-2 text-white text-sm font-light">
                {language_web === "ARE" ?("العودة إلى فريقنا"):("Back To Our Team")} 
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>

    </UserLayout>
  )
}

export default ClientOurTeamView