import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb } from "../component/Breadcrumb";
import { UserLayout } from "../Layout/UserLayout";
import { ImageCard, ImageCardWithoutModal, InfoCard } from "../component/Card";
import cinstance from "../api/axios";
import { useSelector } from "react-redux";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useNavigate } from "react-router-dom";


// const Lawyers = [
//   {
//     name: "Abdulla Al Raisi",
//     designation: "Lawyer",
//     src: `/Images/team/Abdulla_AI_Raisi.png`,
//     alt: "Abdulla",
//   },
//   {
//     name: "Dr. Abdullah Saeed",
//     designation: "Lawyer",
//     alt: "Dr_Abdullah",
//     src: `/Images/team/Dr_Abdullah_Saeed.png`,
//   },
//   {
//     name: "Salem Bin Kowair",
//     designation: "Lawyer",
//     alt: "Salem Bin",
//     src: `/Images/team/Salem_bin_Kowairb.png`,
//   },
//   {
//     name: "Mona Alblooshi",
//     designation: "Lawyer",
//     alt: "Mona Alblooshi",
//     src: `/Images/team/Mona_Ablooshi.png`,
//   },
// ];

// const LegalConsultant = [
//   {
//     name: "Alawi Al Sakkaf",
//     designation: "Legal Consultant",
//     alt: "Alwai_Ai_legal",
//     src: `/Images/team/Alwai_Ai_legal.png`,
//   },
//   {
//     name: "Aref Al Sharafi",
//     designation: "Legal Consultant",
//     alt: "Aref_Ai_Sharf",
//     src: `/Images/team/Aref_Ai_Sharf.png`,
//   },
//   {
//     name: "Awad Elkarim Mohamed",
//     designation: "Legal Consultant",
//     alt: "EI_Fathih_Bagoui",
//     src: `/Images/team/AwadElkarim.png`,
//   },
//   {
//     name: "El Fathih Bagoui",
//     designation: "Legal Consultant",
//     alt: "EI_Fathih_Bagoui",
//     src: `/Images/team/EI_Fathih_Bayoui.png`,
//   },
//   {
//     name: "Yasser ElGendy",
//     designation: "Legal Consultant",
//     alt: "Yasser",
//     src: `/Images/team/Yasser.png`,
//   },
// ];

// const ClientRelation = [
//   {
//     name: "Amira Zaidan",
//     designation: "Head of the Corporate Law Department",
//     alt: "Alwai_Ai_legal",
//     src: `/Images/team/Amira_Zaidan.png`,
//   },
//   {
//     name: "Hussein Adel Youssef",
//     designation: "Head of the Corporate Law Department",
//     alt: "Hussein_Adel_Youssef",
//     src: `/Images/team/Hussein_Adel_Youssef.png`,
//   },
//   {
//     name: "Mohannad Salah Ali Mohammed",
//     designation: "Head of the Corporate Law Department",
//     alt: "Mohanned_Salah_Ali",
//     src: `/Images/team/Mohanned_Salah_Ali.png`,
//   },
// ];


const ClientOurTeam = () => {
  const [allTeamData, setAllTeamData] = useState(null);
  const[globaldata,setGlobalData] = useState()

  const language_web = useSelector((state) => state.webLanguage.value)
  const getTeams = useCallback(async () => {
    try {
      await cinstance({
        url: `/ourteams/get-all-teams/`,
        method: "GET",
      }).then((res) => {
        setAllTeamData(res.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const getGlobalData = useCallback(async()=>{
    try {
      await cinstance({
        url: "/page-intro/find/63e393105a0223b065c4b045",
        method: "GET",
      }).then((res) => {
        setGlobalData(res.data)
      });
    } catch (e) {
      console.error(e);
    }
  }, []);
    
  const navigate = useNavigate()

  useEffect(() => {
    getTeams();
    getGlobalData();
    return () => { };
  }, []);

  return (
    <UserLayout>
      <Breadcrumb image={"ourteam-banner"}  />
      {/* label={  language_web === "ARE" ?("فريقنا"): ("Our Team")}  */}
      {/* <div className="bg-ourteam-banner"></div> */}
      <div className="flex justify-center xl:py-[70px]">
        <InfoCard
          title={ language_web === "ARE" ?("فريقنا"): ("Our Team")}
          paragragh={globaldata?.[language_web]?.ourteam}
        />
      </div>

      <div>
        <div className="container mx-auto xl:pb-[70px]">
          <h1 className="text-4xl font-semibold text-white text-center pb-10 xl:pb-[56px] ">
          {language_web === "ARE" ?("المحامين"):("Lawyers")} 
          </h1>

          {/***Client Relations */}
          {/* <div className='flex flex-wrap gap-y-12  xs:gap-y-0 justify-center gap-x-6 '> */}
         {/* <div className=" lg:ml-28 xl:ml-56 xl:mr-36 ">  */}
            <div className="flex flex-wrap gap-8 xs:gap-y-0 justify-center pb-12 xl:pb-0">
              {allTeamData?.map((value) => {
                
                return (
                  value?.isActive === "active" ? (
                    value?.[language_web]?.position === "Lawyer" ? (
                      <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInLeft">
                        <div >
                          <ImageCard
                            onclick={() => navigate(`/our-team-view?title=${value?.[language_web]?.name}&team_id=${value._id}`)}
                            key={value?._id}
                            src={value?.img}
                            alt={value?.alt}
                            name={value?.[language_web]?.name}
                            designation= {language_web === "ARE" ?("المحامي"):(<span>{value?.[language_web]?.position}</span>)} 
                            description={value?.[language_web]?.desc}
                            expYear={value?.experienceyear}
                          />

                        </div>
                      </AnimationOnScroll>
                    ) : <></>
                  ) : (<></>)

                );
              })}
            </div>
          {/* </div>  */}
         


        </div>

        {/**Legal Consultants */}

        <div className="container mx-auto pb-20 xl:pb-0">
          <h1 className="text-4xl font-semibold text-white text-center pb-10 xl:pb-[56px] ">
          {language_web === "ARE" ?("المستشارين القانونين "):("Legal Consultants")} 
         
          </h1>
          <div className="flex flex-wrap flex-row-reverse pr-3 sm:pr-0 xl:flex-row justify-center xl:justify-start gap-x-6 gap-y-12 ">
            {allTeamData?.map((value, index) => {
              return (
                value?.isActive === "active" ? (
                  value?.[language_web]?.position === "Consultant" ? (
                    <AnimationOnScroll  animateIn="animate__delay-200 animate__animated animate__fadeInRight">
                      <div >
                        <ImageCard
                         onclick={() => navigate(`/our-team-view?title=${value?.[language_web]?.name}&team_id=${value._id}`)}
                         key={value?._id}
                          src={value?.img}
                          alt={value?.alt}
                          name={value?.[language_web]?.name}
                          designation=   {language_web === "ARE" ?("المستشارين"):(<span>{value?.[language_web]?.position}</span>)} 
                          description={value?.[language_web]?.desc}
                          expYear={value?.experienceyear}
                        />
                      </div>
                    </AnimationOnScroll>
                  ) : <></>
                ) : (<></>)

              );
            })}
          </div>
        </div>

        {/***Client Relations */}
        <div className="container mx-auto pb-0 xl:pt-[70px]">
          <h1 className="text-4xl font-semibold text-white text-center pb-10 xl:pb-[56px] ">
          {language_web === "ARE" ?("مدير علاقات العملاء "):("Client Relations")} 
          
          </h1>
          <div className="flex flex-wrap flex-row-reverse pr-3 sm:pr-0 xl:flex-row justify-center xl:justify-start gap-x-6 gap-y-12">
            {allTeamData?.map((value, index) => {
              return (
                value?.isActive === "active" ? (
                  value?.[language_web]?.position === "Client Relations" ?
                    <AnimationOnScroll  animateIn="animate__delay-200 animate__animated animate__fadeInLeft">
                      <div >
                        <ImageCard
                         onclick={() => navigate(`/our-team-view?title=${value?.[language_web]?.name}&team_id=${value._id}`)}
                          key={value?._id}
                          src={value?.img}
                          alt={value?.alt}
                          name={value?.[language_web]?.name}
                          designation={language_web === "ARE" ?("مدير علاقات العملاء "):(<span>{value?.[language_web]?.position}</span>)} 
                          description={value?.[language_web]?.desc}
                          expYear={value?.experienceyear}
                        />
                      </div>
                    </AnimationOnScroll>
                    : <></>
                ) : (<></>)

              );
            })}
          </div>
        </div>


        {/***Legal Researcher */}
        <div className="container mx-auto pb-20 xl:py-[70px]">
          <h1 className="text-4xl font-semibold text-white text-center pb-10 xl:pb-[56px] ">
          {language_web === "ARE" ?("الباحثين القانونيين"):("Legal Researchers")} 
          
            
          </h1>
          <div className="flex flex-wrap flex-row-reverse pr-3 sm:pr-0 xl:flex-row justify-center xl:justify-start gap-x-6 gap-y-12 ">
            {allTeamData?.map((value, index) => {

              return (
                value?.isActive === "active" ? (
                  value?.[language_web]?.position === "Legal Researcher" ?
                    <AnimationOnScroll animateIn="animate__delay-200 animate__animated animate__fadeInRight">
                      <div>
                        <ImageCardWithoutModal
                         key={value?._id}
                         src={value?.img}
                         alt={value?.alt}
                         name={value?.[language_web]?.name}
                         designation=   {language_web === "ARE" ?("الباحث القانوني"):(<span>{value?.[language_web]?.position}</span>)}
                        />
                        {/* <ImageCard
                          key={value?._id}
                          src={value?.img}
                          alt={value?.alt}
                          name={value?.[language_web]?.name}
                          designation={value?.[language_web]?.position}
                          description={value?.[language_web]?.desc}
                        /> */}
                      </div>
                    </AnimationOnScroll>
                    : <></>

                ) : (<></>)

              );
            })}
          </div>
        </div>

      </div>

    </UserLayout>
  );
};

export default ClientOurTeam;
