import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel'
import { PrimaryButton } from "./Atom/form-field";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

export const Breadcrumb = ({ image, label }) => {
    return (

        <div className={`bg-${image} bg-no-repeat h-auto bg-cover md:h-[150px] lg:h-[192px] xl:bg-cover bg-center w-screen sm:h-[170px] xl:h-[290px] `}>

            <div className='container mx-auto pt-auto lg:px-4 xl:px-0 pt-[85px] lg:pt-[135px] xl:pt-[120px]'>
                <h1 className="text-white-wind text-xl xl:text-4xl font-semibold">{label}</h1>
            </div>
        </div>


    )
}

export const CarouselCard = () => {

    const language_web = useSelector((state) => state.webLanguage.value)
    const navigate = useNavigate();
    const [animationClass, setAnimationClass] = useState("animate");
    const [isMuted, setIsMuted] = useState(true);
 
        const handleMute = () => {
            setIsMuted(!isMuted);
        };

    useEffect(() => {
        const styles = `
          @keyframes slide-in {
            0% {
              transform: translateX(-300%);
            }
            100% {
              transform: translateX(0);
            }
          }

          @keyframes slide-out {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-300%);
            }
          }

          #animated-text.animate {
            animation: slide-in 5s forwards;
          }

          #animated-text.move-left {
            animation: slide-out 5s forwards;
          }
        `;

        const styleElement = document.createElement('style');
        styleElement.innerHTML = styles;

        document.head.appendChild(styleElement);

        setTimeout(() => {
            // setAnimationClass("move-left");
        }, 10000);
    }, []);



    return (
        <Carousel autoPlay={true} showThumbs={false} infiniteLoop={true} renderIndicator={false}>
            {language_web === "ARE" ? (
                <div className="arabic-format " style={{ background: `linear-gradient(360deg, rgba(0, 0, 0, 0.616) 0%, rgba(0, 0, 0, 0) 100%)` }}>
                    {/* <div className="absolute invisible md:visible  py-4 pt-[50px] md:pt-[120px]  xl:pt-[260px] 2xl:pt-[220px] pl-4 lg:left-[80px]  xl:left-4 2xl:left-[170px] "> */}


                    <div className="container mx-auto px-4 2xl:pl-0">
                        <div id="animated-text" className={`absolute py-4 pt-[60px] md:pt-[120px] xl:pt-[260px] ${animationClass}`}>
                            <div className="grid text-white space-y-1 md:space-y-2 xl:space-y-4">
                                <span className="uppercase font-extrabold text-xl md:text-xl font-extralight text-start">مرحبا بك في</span>
                                <span className='text-xl font-extrabold sm:text-2xl md:text-7xl xl:text-7xl font-semibold text-start'>بن حيدر</span>
                                <span className='text-xl font-extrabold sm:text-2xl md:text-4xl xl:text-5xl font-semibold text-start'>محاماة واستشارات قانونية</span>
                                <span className="text-md font-extrabold md:text-xl invisible md:visible font-extralight text-start">شركة محاماة موثوقة تتمتع بخبرة تزيد عن 20 عامًا</span>
                            </div>
                        </div>
                    </div>
                    <img src="./Images/banner.jpg"/>
                    {/* <video id="BinhaiderFinalVideo" className="xl:h-[700px] h-auto w-full object-cover" autoPlay loop muted={isMuted}>
                <source src="\video\BinhaiderFinalVideo.mp4" type="video/mp4" />
            </video> */}
            {/* <button onClick={handleMute} style={{ position: 'absolute', top: 0, right: 0 }}>
  {isMuted ? (
    <i className="material-icons" style={{ fontSize: '35px',marginRight: '10px', color: 'white' }}>
      volume_off
    </i>
  ) : (
    <i className="material-icons" style={{ fontSize: '35px',marginRight: '10px', color: 'white' }}>
      volume_up
    </i>
  )}
</button> */}

                    {/* <iframe  className="xl:h-[700px] h-auto w-full object-cover" autoPlay="autoplay" loop muted
                        src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1">
                    </iframe> */}
                    {/* <img src="\Images\Home-building.png" alt="home_building" className="2xl:h-[700px] h-auto w-full object-cover" />  */}

                </div>) : (<div className="arabic-format1" style={{ background: `linear-gradient(180deg, rgba(0, 0, 0, 0.816) 0%, rgba(0, 0, 0, 0) 100%)` }}>
                    {/* <div className="absolute invisible md:visible  py-4 pt-[50px] md:pt-[120px]  xl:pt-[260px] 2xl:pt-[220px] pl-4 lg:left-[80px]  xl:left-4 2xl:left-[170px] "> */}

                    <div className="container mx-auto px-4 2xl:pl-0">
                        <div id="animated-text" className={`absolute py-4 pt-[60px] md:pt-[120px] xl:pt-[260px] ${animationClass}`}>
                            <div className="grid  text-white">
                                <span className="uppercase font-extrabold	 text-xl md:text-xl font-extralight text-start pb-[20px]">Welcome to</span>
                                <span className='text-xl font-extrabold sm:text-2xl md:text-7xl xl:text-7xl font-bold text-start'>Bin Haider</span>
                                <span className='text-xl font-extrabold sm:text-2xl md:text-4xl xl:text-5xl font-bold text-start'>Advocates & Legal Consultants</span>
                                <span className="text-md font-extrabold md:text-xl invisible md:visible font-extralight text-start pt-[20px]">A trusted law firm with over 20 years of experience</span>
                            </div>

                        </div>
                    </div>
                    <img src="./Images/banner.jpg"/>
                    {/* <video id="BinhaiderFinalVideo" className="xl:h-[700px] h-auto w-full object-cover" autoPlay loop muted={isMuted}>
                <source src="\video\BinhaiderFinalVideo.mp4" type="video/mp4" />
            </video> */}
            {/* <button onClick={handleMute} style={{ position: 'absolute', top: 0, right: 0 }}> */}
  {/* {isMuted ? (
    <i className="material-icons" style={{ fontSize: '35px',marginRight: '10px', color: 'white' }}>
      volume_off
    </i>
  ) : (
    <i className="material-icons" style={{ fontSize: '35px',marginRight: '10px', color: 'white'}}>
      volume_up
    </i>
  )} */}
{/* </button> */}






                    {/* <img src="\Images\Home-building.png" alt="home_building" className="2xl:h-[700px] h-auto w-full object-cover" />  */}

                </div>)}

            {/* <div>
                <div className="absolute container  mx-auto py-4 xl:pt-[200px] 2xl:pt-[224px] pl-4 2xl:left-[170px] ">
                    <div className="grid text-gray-800 space-y-1 md:space-y-2 xl:space-y-4">
                        <span className="uppercase text-xs md:text-sm font-extralight text-start ">Welcome to</span>
                        <span className='text-5xl font-bold text-start'>Bin Haider</span>
                            <span className='text-2xl font-bold text-start'>Advocates & Legal Consultants.</span>
                        <span className="text-xs md:text-sm font-extralight text-start">A trusted law firm with over 20 years of experience</span>
                        <div className="text-start"><PrimaryButton onclick={() => navigate('/contact-us')} label={'Contact us'} /></div>
                     
                    </div>

                </div>
                <img src="\Images\Home-building.png" alt="home_building" className="xl:h-[700px] h-auto w-full object-cover" />

            </div>   */}

            {/* <div>
                <div className="absolute container  mx-auto pt-[224px] pl-4 left-[170px] ">
                    <div className="grid text-true-gray-800 space-y-1 md:space-y-2 2xl:space-y-4">
                        <span className=" uppercase text-xs md:text-sm font-extralight text-start ">Welcome to</span>
                        <span className='text-5xl font-bold text-start'>Bin Haider<br />

                            Advocates & Legal<br />
                            Consultants.</span>
                        <span className=" text-xs md:text-sm font-extralight text-start">A trusted law firm with over 20 years of experience</span>
                        <div className="text-start"><PrimaryButton onclick={() => navigate('/contact-us')} label={'Contact us'} /></div>
                     
                    </div>

                </div>
                <img src="\Images\Practice\Retail.png" alt="retail" className="2xl:h-[700px] h-auto w-full object-cover" />

            </div> */}

            {/* <div>
                <div className="absolute container  mx-auto pt-[224px] pl-4 left-[170px] ">
                    <div className="grid text-gray-800 space-y-1 md:space-y-2 2xl:space-y-4">
                        <span className=" uppercase text-xs md:text-sm font-extralight text-start ">Welcome to</span>
                        <span className='text-5xl font-bold text-start'>Bin Haider<br />

                            Advocates & Legal<br />
                            Consultants.</span>
                        <span className=" text-xs md:text-sm font-extralight text-start">A trusted law firm with over 20 years of experience</span>
                        <div className="text-start"><PrimaryButton onclick={() => navigate('/contact-us')} label={'Contact us'} /></div>
                     
                    </div>

                </div>
                <img src="\Images\Home-building.png" alt="home_building" className="2xl:h-[700px] h-auto w-full object-cover" />

            </div>   */}


            {/* <div>
                <video className="2xl:h-[700px] h-auto w-full object-cover" loop autoplay muted controls>
                    <source src="\bannervideo.mp4" type="video/mp4"/>
                </video>
            </div> */}

        </Carousel>
    )
}
