import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '970px',
  height: '400px'
};

const center = {
    lat: 25.2303,
    lng: 55.2871
   
};
function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDt3A-hZ57kwVZqnlDWQzw2t_IIcG28j4g"
  })

  const Options = {
    disableDefaultUI: false,
    mapTypeControl: true,
    streetViewControl: false,

}
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        options={Options}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        defaultStreetView={false}
        disableDefaultUI={true}
      >
       
        <>
        <Marker
         position={{ lat:25.2303 , lng:  55.2871 }} 
        >
          
        </Marker>
        </>
      </GoogleMap>
  ) : <></>
}

export default React.memo(Map)