import axios from "axios"; 

const cinstance = axios.create({
  // baseURL:"http://localhost:4000",
baseURL:"https://api.binhaider.amazing7studios.com",
  headers:{
    timeout:1000,
  }
})

export default cinstance;