


export const HeaderData = {
  ARE : [
    {
        "Home": "Home",
        "title": "الرئيسية",
        "path": "/"
    },
    {
        "About us": "About Us",
        "title": "معلومات عنا",
        "path": "/about-us"
    },
    {
        "Our team": "Our Team",
        "title": "فريقنا",
        "path": "/our-team"
    },
    {
        "Practice area": "Practice Area",
        "title": "المجالات المتخصصة",
        "path": "/practice-area"
    },
    {
        "Blog": "Blog",
        "title":  "المدونة",
        "path": "/blog"
    },
    {
        "Contact us": "Contact Us",
        "title": " اتصل بنا ",
        "path": "/contact-us"
    }
 ],
 en:[
    {
        "title":  "Home",
        "path": "/"
    },
    {
        "title":  "About Us",
        "path": "/about-us"
    },
    {
        "title": "Our Team",
        "path": "/our-team"
    },
    {
        "title": "Practice Area",
        "path": "/practice-area"
    },
    {
        "title": "Blog",
         "path": "/blog"
    },
    {
        "title": "Contact Us",
        "path": "/contact-us"
    }
 ]
}