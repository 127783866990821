import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from 'react-modal';
import { useSelector } from "react-redux";

export const LongTextSlider = ({ content, limit }) => {
    const [showAll, setShowAll] = useState(false);

    const showMore = () => setShowAll(true);
    const showLess = () => setShowAll(false);

    if (content.length <= limit) {

        return (<p className='text-xs sm:text-sm xl:text-sm font-extralight text-start pt-2 sm:pb-4 2xl:pb-8' dangerouslySetInnerHTML={{ __html: content }} />)
    }
    // if (showAll) {
    //   // We show the extended text and a link to reduce it
    //   return <div> 
    //     {content} 
    //     <button onClick={showLess}>Read less</button> 
    //   </div>
    // }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = content.substring(0, limit) + "...";

    return <div>
        {toShow}
        {/* <PrimaryButton onclick={onclick} label={'Read More'} /> */}
    </div>
}




export const CardSlider = ({ title, content, src, alt, key }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const language_web = useSelector((state) => state.webLanguage.value)

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            padding: "0",
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#171717',
            overflowY: "auto",
            maxHeight: "80vh",

        },
    };
    var trimmedString = content.substr(0, 150);
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))


    return (
        <>
            <div key={key} className='transform transition duration-500 hover:scale-95'>
                <>
                    <div className='w-full xl:w-[370px] xl:h-[400px]'>
                        <img src={src} alt={alt} className="w-full  xl:w-[370px] h-[260px] sm:h-[300px] md:h-[360px] xl:h-[200px]  " />
                        <div className='h-[230px] text-white-wind bg-true-gray-800'>
                            <div className="px-4 pb-4">
                                {language_web === "ARE" ? (<div className="h-[150px] arabic-format">
                                    <h3 className='text-sm xl:text-[24px] leading-[32px] break-all font-semibold text-start  h-[48px] w-[340px] truncate capitalize pt-4'>{title}</h3>
                                    <p className='text-[12px] leading-[24px] font-extralight text-start' dangerouslySetInnerHTML={{ __html: trimmedString + "..." }} />
                                </div>) : (<div className="h-[150px]">
                                    <h3 className='text-lg xl:text-[24px] leading-[32px] break-all font-semibold text-start  h-[48px] w-[340px] truncate capitalize pt-4'>{title}</h3>
                                    <p className='text-[14px] leading-[24px] font-extralight text-start' dangerouslySetInnerHTML={{ __html: trimmedString + "..." }} />
                                </div>)}
                                {language_web === "ARE" ? (
                                    <a onClick={openModal}><label className='arabic-format text-sm font-medium text-blue-500 text-start cursor-pointer'> المزيد </label></a>
                                ) : (
                                    <a onClick={openModal}><label className='text-sm font-medium text-blue-500 text-start cursor-pointer'>Read More</label></a>
                                )}

                            </div>

                        </div>

                    </div>
                </>

                <Modal
                    closeTimeoutMS={500}
                    ariaHideApp={false}
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <>
                        {language_web === "ARE" ? (<div className='h-auto w-auto '>
                            <div className='container mx-auto px-4 pb-4 arabic-format'>
                                <div onClick={closeModal} className="text-lg cursor-pointer font-semibold text-right text-white">X</div>
                                <div className='grid lg:grid-cols-2 items-center'>
                                    <img src={src} alt={alt} className='w-auto h-auto sm:h-[400px] object-cover' />
                                    <div className='container mx-auto xl:px-4 xl:pb-4 text-white-wind space-y-2'>
                                        <h1 className='font-semibold text-2xl pb-4 capitalize'>{title}</h1>
                                        <p className='text-[14px] leading-[24px] font-extralight text-start' dangerouslySetInnerHTML={{ __html: content }} />
                                    </div>
                                </div>
                            </div>
                        </div>) : (<div className='h-auto w-auto '>
                            <div className='container mx-auto px-4 pb-4 '>
                                <div onClick={closeModal} className="text-lg cursor-pointer font-semibold text-right text-white">X</div>
                                <div className='grid lg:grid-cols-2 items-center'>
                                    <img src={src} alt={alt} className='w-auto h-auto sm:h-[400px] object-cover' />
                                    <div className='container mx-auto xl:px-4 xl:pb-4 text-white-wind space-y-2'>
                                        <h1 className='font-semibold text-2xl pb-4 capitalize'>{title}</h1>
                                        <p className='text-[14px] leading-[24px] font-extralight text-start' dangerouslySetInnerHTML={{ __html: content }} />
                                    </div>
                                </div>
                            </div>
                        </div>)}

                    </>


                </Modal>


            </div>
        </>
    )
}

{/**



const [sliderSettings, setSliderSettings] = useState({
   infinite: true,
   speed: 500,
   slidesToShow: 2,
   slidesToScroll: 1,
   arrows: false,
   responsive:[
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots:true
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots:true
      }
    }
   ]
 })

 const gotoNext = () => {
   customeSlider.current.slickNext()
 }

 const gotoPrev = () => {
   customeSlider.current.slickPrev()
 }
*/}


// function SampleNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{
//                 ...style, display: "block", backgroundColor: "#737373", width: "30px",
//                 height: "30px", display: "flex", justifyContent: "space-around",
//                 alignItems: "center"
//             }}
//             onClick={onClick}
//         />
//     );
// }

// function SamplePrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={className}
//             style={{
//                 ...style, display: "block", backgroundColor: "#737373", width: "30px",
//                 height: "30px", display: "flex", justifyContent: "space-around",
//                 alignItems: "center"
//             }}
//             onClick={onClick}
//         />
//     );
// }

export const MultipleSlider = ({ children }) => {
    var settings = {
         centerMode: true,
        autoplay: true,
        centerPadding: '0px',
        lazyLoad: true,
        slidesToShow: 3,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,

        responsive: [
            {
                breakpoint: 1280,
                setting: {
                    arrrows: true,
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    centerMode: true,
                    autoplay: true,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 920,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    centerMode: true,
                    autoplay: true,
                    centerPadding: '0px',
                   
                },
           },
           {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                arrows: false,
                autoplay: true,
                centerPadding: '1px',
               
            },
       },
           
        ],
    };

    

    return (

        <Slider {...settings}>

            {children}


        </Slider>

    );
}
