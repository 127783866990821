import React, { useCallback, useEffect, useRef, useState } from "react";
import cinstance from "../api/axios";
import { Breadcrumb } from "../component/Breadcrumb";
import { CardRight, CardLeft, InfoCard } from "../component/Card";
import { UserLayout } from "../Layout/UserLayout";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const ClientPracticeArea = () => {
  const [globaldata, setGlobalData] = useState()
  const [allPracticesData, setAllPracticesData] = useState(null);
  const language_web = useSelector((state) => state.webLanguage.value)
  const location = useLocation();
  const passedId = location.hash.split('#')[1]

  const getPractices = useCallback(async () => {
    try {
      await cinstance({
        url: `/practice/all-practice`,
        method: "GET",
      }).
        then((res) => {
          setAllPracticesData([])

          res.data.map((value, id) => {
            if (value.status == "active")
              setAllPracticesData((prev) => [...prev, value]);

          })
        });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const goToViolation = (id) => {
      const violation = document.getElementById(id);
      window.scrollTo({
        top: violation?.offsetTop,
        behavior: "smooth"
      });
    };
    goToViolation(passedId)
  }, [passedId])

  const getGlobalData = useCallback(async () => {
    try {
      await cinstance({
        url: "/page-intro/find/63e393105a0223b065c4b045",
        method: "GET",
      }).then((res) => {
        setGlobalData(res.data)
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getPractices();
    getGlobalData();
    return () => { };
  }, []);

  const whyChooseUs = {
    "data" : {
      "en" : {
        "data" : "<p>We are a team of lawyers specializing in various areas of law, committed to providing high-quality legal services, based on a deep understanding of the law and the needs of our clients. Whether you need legal advice, strong legal representation in court, or support in resolving disputes, we are here to ensure that justice is achieved and your rights are protected.</p>"
      },
      "ARE" : {
        "data" : "<p class=\"MsoNormal\" dir=\"RTL\" style=\"direction: rtl; unicode-bidi: embed;\"><span lang=\"AR-EG\">نحن فريق من المحامين المتخصصين في مجاالت متعددة من القانون، نلتزم بتقديم خدمات قانونية عالية الجودة، تعتمد على فهم عميقللقانون واحتياجات عمالئنا. سواء كنت بحاجة إلى استشارة قانونية، أو تمثيل قانوني قوي في المحاكم، أو دعم في حل النزاعات، فن حنهنا لضمان تحقيق العدالة وحماية حقوقك</span></p>"
      }
    },
  }

  return (
    <UserLayout>
      <Breadcrumb image={"practice-banner"} />
      <div className="flex justify-center xl:py-[70px]">
        <InfoCard
          title={language_web === "ARE" ? ("تخصصاتنا") : ("Our Specialties")}
          heading={language_web === "ARE" ? ("ما نحن خبراء فيه") : ("WHAT WE ARE EXPERT AT")}
          paragragh={globaldata?.[language_web]?.practice}
        />
      </div>
      <div className="">
        {allPracticesData?.map((value, idx) => (
          <div key={idx}>
            {idx % 2 == 0 ? (
              (<section>
                <CardRight
                  id={value?.en?.title}
                  title={value?.[language_web]?.title}
                  Content={value?.[language_web]?.desc}
                  src={value?.en?.img}
                  alt=""
                />
              </section>)

            ) : (
              (<section>
                <CardLeft
                  id={value?.en?.title}
                  title={value?.[language_web]?.title}
                  Content={value?.[language_web]?.desc}
                  src={value?.en?.img}
                  alt=""
                />
              </section>)

            )}
          </div>
        ))}
      </div>
      <div className="flex justify-center xl:py-[70px] ">
        <InfoCard
          heading={language_web === "ARE" ? ("لماذا تختار مكتبنا؟") : ("Why choose our office?")}
          paragragh={whyChooseUs?.data?.[language_web]?.data}
        />
      </div>
    </UserLayout>
  );
};

export default ClientPracticeArea;
