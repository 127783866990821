/* eslint-disable jsx-a11y/iframe-has-title */

import React, { useState } from 'react'
import { InputField, PrimaryButton, TextField } from '../component/Atom/form-field'
import { AttherateIcon, MapPin, PhoneIcon } from '../component/Atom/icons'
import { Breadcrumb } from '../component/Breadcrumb'
// eslint-disable-next-line no-unused-vars  
import Map from '../component/Map'

import { UserLayout } from '../Layout/UserLayout'
import swal from 'sweetalert';
import cinstance from '../api/axios'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ContactUs = () => {
    const [data, setData] = useState({
        name: '',
        email: '',
        contactNo: '',
        company: '',
        message: '',

    })

    const navigate = useNavigate()
    const handleSubmit = async (e) => {

        e.preventDefault();
        try {

            await cinstance({
                url: "/contact/send-details",
                method: "POST",
                data: data,
            }).then((res) => {
                if (res.status === 200) {
                    swal({
                        title: "From Submitted Success",
                        text: "We will resolve Your Query Soon",
                        icon: "success",
                        buttons: "OK!"
                    })
                    navigate('/')
                }
                else {
                    swal({
                        text: "Some thing went Wrong",
                        icon: "warning",
                        buttons: "OK!"
                    })
                }
            })
        } catch (err) {
            console.error(err)
        }
    }




    const handleChange = (e) => {
        let value = e.target.value;
        let name = e.target.name;

        setData((prevalue) => {
            return {
                ...prevalue,   // Spread Operator               
                [name]: value
            }
        })

    }

    const language_web = useSelector((state) => state.webLanguage.value)

    return (
        <UserLayout>
            <Breadcrumb image={'contact-banner'} />
            {/* label={language_web === "ARE" ? ("اتصل بنا") : ("Contact Us")}  */}
            {/* <div className='bg-contact-banner'></div> */}
             <div className='container mx-auto lg:pb-[70px]  pb-[30rem] md:pb-[20rem]'>
                <div className='lg:bg-primary-wind w-auto h-auto mt-8 lg:mt-[396px]'>
                    <div className='container mx-auto'>
                        <div className='flex flex-col items-center'>
                            <div className="absolute md:bottom-0 lg:bottom-20 sm:top-[20%] md:top-[280px] lg:top-[360px] xl:top-[420px]  bg-contact-form w-auto h-auto md:h-[800px]  lg:w-[970px] lg:h-[647px] mb-16">
                                <div className='container mx-auto p-4 md:p-14'>
                                    <div className='grid grid-cols-1 lg:grid-cols-2 justify-between'>

                                        <div className='container mx-auto text-white-wind py-20 md:py-0 lg:py-20'>
                                            {language_web === "ARE" ? (
                                                (<>
                                                    <div className='space-y-2 xl:space-y-4'>
                                                        <h3 className='text-2xl font-extralight'>ابقى على تواصل</h3>
                                                        <h1 className='text-3xl lg:text-4xl font-semibold'>اترك لنا معلوماتك وسنعاود الاتصال بك.</h1>
                                                    </div>
                                                    <div className='space-y-2 xl:space-y-8 xl:pt-[56px]'>
                                                        <div className='font-extralight text-sm flex items-center space-x-3 '>
                                                            <span className='p-1'><PhoneIcon /></span>
                                                            <span className='text-lg'>
                                                            <a href="tel:۷۷۷۸۸٥۳-۱۰"> ۷۷۷۸۸٥۳-۱۰</a>
                                                                {/* <a href="tel:٦۹۸۰۰٤۱٥٥۰">٦۹۸۰۰٤۱٥٥۰</a> */}
                                                                </span>
                                                        </div>
                                                        <div className='arabic-format font-extralight text-sm flex items-center space-x-3  '>
                                                            <span className='p-1'><AttherateIcon /></span>
                                                            <span> <a  href="mailto:info@bhadvoc.com">info@bhadvoc.com</a></span>
                                                        </div>
                                                        <div className='font-extralight text-sm flex space-x-3 '>
                                                            <span className='p-1'><MapPin /></span>
                                                            <span>1801 و 1802 ، برج مكاتب فندق ذا إتش ، واحد
                                                                شارع الشيخ زايد ، ص. ب: 98444 ، دبي ، الإمارات العربية المتحدة..</span>
                                                        </div>
                                                    </div>
                                                </>)
                                            ) :
                                                (<>{/***English */}
                                                    <div className='space-y-2 xl:space-y-4'>
                                                        <h3 className='text-2xl font-extralight'>Get in Touch</h3>
                                                        <h1 className='text-3xl lg:text-4xl pb-2 lg:pb-0 font-semibold'>Leave us your info and we will get back to you.</h1>
                                                    </div>
                                                    <div className='space-y-2 xl:space-y-8 xl:pt-[56px]'>
                                                        <div className='font-extralight text-sm flex items-center space-x-3 '>
                                                            <span><PhoneIcon /></span>
                                                            <a href="tel:04 3588777">04-3588777</a>
                                                            {/* <a href="tel:055 140 0896"> 055 140 0896 </a> */}
                                                            {/* <span>055 140 0896</span> */}
                                                        </div>
                                                        <div className='font-extralight text-sm flex items-center space-x-3 '>
                                                            <span><AttherateIcon /></span>
                                                            <a href="mailto:info@bhadvoc.com"> info@bhadvoc.com </a>
                                                            {/* <a href="https://mail.google.com/mail/?view=cm&fs=1&to=someone@example.com&cc=someone@ola.example&bcc=someone.else@example.com&su=SUBJECT&body=BODY" target="_blank">Mail Us</a> */}
                                                            {/* <span>info@bhadvoc.com</span> */}
                                                        </div>
                                                        <div className='font-extralight text-sm flex  space-x-3 '>
                                                            <span className='pt-1'><MapPin /></span>
                                                            <span>1801 & 1802, The H Hotel Office Tower, One
                                                                Sheikh Zayed Rd, P.O. Box: 98444, Dubai, U.A.E.</span>
                                                        </div>
                                                    </div>
                                                </>)}

                                            {/* <div className='xl:space-y-8 xl:pt-[56px]'>
                                                <div className='font-extralight text-sm flex items-center space-x-3 '>
                                                    <span><PhoneIcon /></span>
                                                    <span>+971 4 421 3914</span>
                                                </div>
                                                <div className='font-extralight text-sm flex items-center space-x-3 '>
                                                    <span><AttherateIcon /></span>
                                                    <span>info@bhadvoc.com</span>
                                                </div>
                                                <div className='font-extralight text-sm flex  space-x-3 '>
                                                    <span className='pt-1'><MapPin /></span>
                                                    <span>1801 & 1802, The H Hotel Office Tower, One
                                                        Sheikh Zayed Rd, P.O. Box: 98444, Dubai, U.A.E.</span>
                                                </div>
                                            </div> */}

                                        </div>


                                        <div className='container mx-auto sm:pt-6  xl:pl-24'>
                                            <form onSubmit={handleSubmit}>
                                                <div className='space-y-4 xl:space-y-8'>
                                                    <InputField type={'text'} name={'name'} onchange={handleChange} placeholder={'Full Name*'} required />
                                                    <InputField type={'email'} name={'email'} onchange={handleChange} placeholder={'Email*'} required/>
                                                    <InputField type={'tel'} maxLength={"12"} minLength={"10"} name={'contactNo'} onchange={handleChange} placeholder={'Phone Number*'} required />
                                                    <InputField type={'text'} name={'company'} onchange={handleChange} placeholder={'Company'} />
                                                    <TextField type={'text'} name={'message'}  onchange={handleChange} placeholder={'Message'} required/>
                                                </div>
                                                <div className='pt-4 xl:pt-[30px]'>
                                                    <PrimaryButton label={'Submit'} />
                                                </div>
                                            </form>
                                        </div>


                                    </div>

                                </div>
                            </div>


                        </div>
                        <div className='flex flex-col items-center invisible lg:visible '>
                            <div className=' lg:pt-[400px] xl:pt-[391px] pb-[70px]'>
                                <iframe src="https://maps.google.com/maps?q=1%20Sheikh%20Zayed%20Rd%20-%20Trade%20Centre%20-%20Trade%20Centre%201%20-%20Dubai%20-%20United%20Arab%20Emirates&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                                 width="970px"
                                  height="450px" 
                                  allowfullscreen="" 
                                  loading="lazy" 
                                  referrerpolicy="no-referrer-when-downgrade">
                                </iframe>
                               
                                {/* <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="100%" id="gmap_canvas"
                                 src="https://maps.google.com/maps?q=1801 & 1802, The H Hotel Office Tower, One Sheikh Zayed Rd, P.O. Box: 98444, Dubai, U.A.E.&t=&z=10&ie=UTF8&iwloc=&output=embed" 
                                 frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                    </iframe><a href="https://2yu.co">2yu</a><br><style>.mapouter{position:relative;text-align:right;height:100%;width:100%;}</style>
                                    <a href="https://embedgooglemap.2yu.co/">
                                    html embed google map</a>
                                    <style>.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}</style></div></div> */}
                             
                                 {/* <Map />  */}
                                {/* <img src='\Images\map.png' className='' /> */}
                            </div>

                        </div>


                    </div>
                </div>

            </div>

        </UserLayout>
    )
}

export default ContactUs;