import React, { useState } from 'react'
import Modal from 'react-modal';
import { PrimaryButton } from './Atom/form-field';
import { useSelector } from "react-redux";


export const InfoCard = ({ title, paragragh, heading }) => {
  return (
    <div className=' text-white-wind w-auto xl:w-[970px] h-auto'>
      <div className='container mx-auto  text-center space-y-2 p-4 xl:p-0'>
        {title && <h1 className='text-4xl font-semibold text-white-wind'>{title}</h1>}
        {heading && <h3 className='font-semibold text-2xl pt-2 text-white-wind'>{heading}</h3>}
        {paragragh && <p className='font-extralight text-[14px] leading-[24px] text-white-wind items-center ' dangerouslySetInnerHTML={{ __html: paragragh }} />}
      </div>
    </div>
  )
}


export const MessageBox = ({ bgcolor, desc }) => {
  const language_web = useSelector((state) => state.webLanguage.value)
  return (
    // <div className={`bg-[${bgcolor}]  text-white-wind w-auto md:w-[50%] xl:w-[640px] 2xl:w-[900px] h-auto`}>
    <div className='bg-primary lg:bg-transparent text-white-wind w-auto h-auto'>
      {/* <div className='container mx-auto xl:pl-8 2xl:pl-48 2xl:pr-[30px] px-4  py-8 xl:py-16'> */}
      <div className='lg:pt-[100px] p-6 lg:p-4'>
        {/* <div className='text-white-wind '>
       <div className=''> */}
        <h1 className='uppercase font-bold text-lg lg:text-xl xl:text-4xl text-white-wind pb-4'>{language_web === "ARE" ? ("رؤيتنا") : ("Our Vision")}</h1>
        <p className=' text-[14px] leading-[24px] font-extralight break-words text-white-wind' dangerouslySetInnerHTML={{ __html: desc }} />
        <div className='flex gap-4 pt-6'>
          <div className={`bg-white p-[1px] w-1 h-[48px]`} />
          {language_web === "ARE" ? (
            <div>
              <h1 className='text-xl xl:text-2xl font-semibold text-white-wind'>السيد عيسى بن حيدر</h1>
              <h2 className='text-[14px] leading-[24px] font-extralight'>الرئيس التنفيذي ومؤسس بن حيدر</h2>
            </div>
          ) : (
            <div>
              <h1 className='text-xl xl:text-2xl font-semibold text-white-wind '>Mr. ISA BIN HAIDER</h1>
              <h2 className='text-[14px] leading-[24px] font-extralight'>CEO & Founder of Bin Haider</h2>
            </div>
          )}

        </div>
      </div>
    </div>
  )
}


export const ImageCard = ({ name, designation, src, alt, description, expYear, key, onclick }) => {
  const language_web = useSelector((state) => state.webLanguage.value)

  const [modalIsOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  return (
    //   <div className='transform transition duration-700 hover:scale-105'>
    //     <div key={key} onClick={onclick} className={`cursor-pointer `} >
    //       <div className='w-80 h-80 xl:w-[370px] xl:h-[370px] bg-no-repeat bg-cover bg-center' style={{ backgroundImage: `url(${src})` }}>
    //       <div className='grid'>
    //           <div className='bg-transparent h-48 xl:w-[370px] xl:h-[260px]' />
    //           <div className='h-32 xl:h-[110px]'style={{ background:`linear-gradient(360deg, rgba(0, 0, 0, 0.616) 0%, rgba(0, 0, 0, 0) 100%)` }}>
    //             <div className='grid pt-12'>
    //               <span className='text-white-wind font-semibold text-xl break-words truncate px-4'>{name}</span>
    //               <span onMouseEnter={onHover} onMouseLeave={onHover} className='text-white-wind font-normal text-base px-4'>
    //                 {hover ? <span className='cursor-pointer text-white'>View Profile</span> : <span> {designation}</span>}
    //               </span>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    // </div>

    <div>
      <div className=" group container flex justify-center items-center  mx-auto content-div">
        <div>
          <div className='w-80 h-80 sm:w-[350px] xl:w-[370px] xl:h-[370px] bg-no-repeat bg-cover bg-center' style={{ backgroundImage: `url(${src})` }}>
            <div className='grid'>
              <div className='bg-transparent h-48 sm:w-[350px] xl:w-[370px] xl:h-[260px]' />
              <div className='h-32 xl:h-[110px]' style={{ background: `linear-gradient(360deg, rgba(0, 0, 0, 0.616) 0%, rgba(0, 0, 0, 0) 100%)` }}>
                <div className='grid pt-16 sm:pt-18 lg:pt-12'>
                  <span className='text-white-wind font-semibold text-xl break-words truncate px-4'>{name}</span>
                  <span onMouseEnter={onHover} onMouseLeave={onHover} className='text-white-wind text-[14px] leading-[24px] font-extralight px-4'>
                    {designation}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute opacity-0 transition-all duration-150 ease-in delay-150 w-80 h-80 sm:w-[350px] xl:w-[370px] xl:h-[370px] group-hover:opacity-100  hover:transparent" style={{ background: "rgba(0,0,0,0.5)" }} >

          <div className="pt-36 lg:pt-[185px] text-center flex items-center  justify-center">
            <button onClick={onclick} className="text-center rounded-lg p-4   text-white font-semibold text-lg" style={{ background: "rgba(0,0,0,0.9)" }}>
              {language_web === "ARE" ? ("عرض الصفحة الشخصية") : (" View Profile")}
            </button>
          </div>
        </div>
      </div>

    </div>


  )
}

export const ImageCardWithoutModal = ({ name, designation, src, key }) => {
  return (
    <div className='transform transition duration-700 hover:scale-105'>
      <div key={key} >
        <div className='w-80 h-80 xl:w-[370px] xl:h-[370px]  bg-no-repeat bg-cover bg-center' style={{ backgroundImage: `url(${src})` }}>
          {/* <img src={src} alt={alt} className='h-full w-full object-contain'/> */}
          <div className='grid'>
            <div className='bg-transparent h-48 xl:w-[370px] xl:h-[260px]' />
            <div className='h-32 xl:h-[110px]' style={{ background: `linear-gradient(360deg, rgba(0, 0, 0, 0.616) 0%, rgba(0, 0, 0, 0) 100%)` }}>
              <div className='grid pt-16 sm:pt-18 lg:pt-12'>
                <span className='text-white-wind font-semibold text-xl break-words truncate px-4'>{name}</span>
                <span className='text-white-wind text-[14px] leading-[24px] font-extralight px-4'>
                  {designation}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )

}

export const CardRight = ({ title, Content, bgcolor, src, alt, id }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const language_web = useSelector((state) => state.webLanguage.value)
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  const paragragh = Content.slice(0, 200);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: "0",
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#171717',
      overflowY: "auto",
      maxHeight: "80vh"
    },
  };

  return (
    <>
      {/* <div className="flex flex-wrap flex-row lg:flex-row-reverse items-center "> */}
      <div id={id} className='flex flex-wrap-reverse justify-center items-center sm:flex-wrap sm:flex-row-reverse'>
        <div className="w-[370px] sm:w-[50%] h-auto">
          <div className={`bg-[${bgcolor}] text-white-wind h-auto`}>

            <div className='container mx-auto p-4 xl:pl-8 2xl:pl-[56px]'>
              {title && <h1 className='text-lg xl:text-2xl font-semibold pb-4 capitalize'>{title}</h1>}
              <p className='text-[14px] leading-[24px] font-extralight text-start' dangerouslySetInnerHTML={{ __html: paragragh + "..." }} />
              <div className='pt-[32px]'>
                <PrimaryButton onclick={openModal} label={language_web === "ARE" ? (" المزيد ") : ("Read More")} />
              </div>
            </div>
          </div>
        </div>
        <img src={src} alt={alt} className="w-[370px] sm:w-[50%] h-auto xl:h-[470px] lg:object-cover object-fill" />
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        closeTimeoutMS={500}
        contentLabel="Example Modal"
      >

        <>
          {language_web === "ARE" ? (<div className='h-auto w-auto '>
            <div className='container mx-auto  px-8 py-4 arabic-format '>
              <div onClick={closeModal} className="text-lg cursor-pointer font-semibold text-right text-white">X</div>
              <div className='grid lg:grid-cols-2 gap-6 items-center'>
                <img src={src} alt={alt} className='w-auto h-auto sm:h-[400px] object-cover' />
                <div className='container mx-auto xl:px-6 xl:pb-4 text-white-wind space-y-2 '>

                  <h1 className='font-semibold text-2xl pb-4 capitalize'>{title}</h1>
                  <p className='text-[14px] leading-[24px] font-extralight text-start fake-class' dangerouslySetInnerHTML={{ __html: Content }} />
                </div>
              </div>
            </div>
          </div>) : (<div className='h-auto w-auto '>
            <div className='container mx-auto  px-8 py-4'>
              <div onClick={closeModal} className="text-lg cursor-pointer font-semibold text-right text-white">X</div>
              <div className='grid lg:grid-cols-2 gap-6 items-center'>
                <img src={src} alt={alt} className='w-auto h-auto sm:h-[400px] object-cover' />
                <div className='container mx-auto xl:px-6 xl:pb-4 text-white-wind space-y-2'>
                  <h1 className='font-semibold text-2xl pb-4 capitalize'>{title}</h1>
                  <p className='text-[14px] leading-[24px] font-extralight text-start fake-class' dangerouslySetInnerHTML={{ __html: Content }} />
                </div>
              </div>
            </div>
          </div>)}

        </>
      </Modal>
    </>

  )
}



export const CardLeft = ({ title, Content, bgcolor, src, alt, id }) => {
  const language_web = useSelector((state) => state.webLanguage.value)
  const [modalIsOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: "0",
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#171717',
      overflowY: "auto",
      maxHeight: "80vh"
    },
  };
  const paragragh = Content?.slice(0, 200);
  return (
    <>
      <div id={id} className="flex flex-wrap-reverse justify-center sm:flex-row items-center">
        <div className="w-[370px] sm:w-[50%] h-auto">
          <div className={`bg-[${bgcolor}] text-white-wind h-auto`}>
            <div className='container mx-auto p-4 xl:pl-8 2xl:pl-52  '>
              {title && <h1 className='text-lg xl:text-2xl font-semibold pb-4 capitalize'>{title}</h1>}
              <p className='text-[14px] leading-[24px] font-extralight text-start' dangerouslySetInnerHTML={{ __html: paragragh + "..." }} />
              <div className='pt-[32px]'>
                <PrimaryButton onclick={openModal} label={language_web === "ARE" ? (" المزيد ") : ("Read More")} />
              </div>
            </div>
          </div>
        </div>
        <img src={src} alt={alt} className="w-[370px] sm:w-[50%] h-auto xl:h-[470px] lg:object-cover object-contain" />
      </div>
      <Modal
        closeTimeoutMS={500}
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <>
          {language_web === "ARE" ? (<div className='h-auto w-auto '>
            <div className='container mx-auto px-8 py-4 arabic-format '>
              <div onClick={closeModal} className="text-lg cursor-pointer font-semibold text-right text-white">X</div>
              <div className='grid lg:grid-cols-2 items-center'>
                <img src={src} alt={alt} className='w-auto h-auto sm:h-[400px] object-cover' />
                <div className='container mx-auto xl:px-6 xl:pb-4 text-white-wind space-y-2'>
                  <h1 className='font-semibold text-2xl pb-4 capitalize'>{title}</h1>
                  <p className='text-[14px] leading-[24px] font-extralight text-start fake-class' dangerouslySetInnerHTML={{ __html: Content }} />
                </div>
              </div>
            </div>
          </div>) : (<div className='h-auto w-auto '>
            <div className='container mx-auto px-8 py-4'>
              <div onClick={closeModal} className="text-lg cursor-pointer font-semibold text-right text-white">X</div>
              <div className='grid lg:grid-cols-2 items-center'>
                <img src={src} alt={alt} className='w-auto h-auto sm:h-[400px] object-cover' />
                <div className='container mx-auto xl:px-6 xl:pb-4 text-white-wind space-y-2'>
                  <h1 className='font-semibold text-2xl pb-4 capitalize'>{title}</h1>
                  <p className='text-[14px] leading-[24px] font-extralight text-start fake-class' dangerouslySetInnerHTML={{ __html: Content }} />
                </div>
              </div>
            </div>
          </div>)}

        </>


      </Modal>
    </>

  )
}

export const BlogCard = ({ title, date, description, heading, href, src, alt, key, onclick }) => {
  const language_web = useSelector((state) => state.webLanguage.value)
  var d = new Date(date);
  var date = d.getDate();
  var month = d.getMonth() + 1;
  var year = d.getFullYear();
  var newDate = date + "/" + month + "/" + year;
  const paragragh = description?.slice(0, 100);

  return (
    <div onClick={onclick} key={key} className='text-white-wind w-[370px] h-[430px] cursor-pointer opacity-60'>
      <div className='container mx-auto px-4 sm:px-0'>
        <img src={src} alt={alt} className="w-[370px] h-[200px] object-cover" />
        <div className='w-[370px] h-[190px] sm:h-[200px] '>
          <div className='pt-4 pb-2'>
            <div className='flex justify-between items-center '>
              {title && <span className='bg-primary font-normal items-start text-xs px-4 py-2'>{title}</span>}
              {newDate && <span className='font-normal text-xs pr-6 sm:pr-0'>{newDate}</span>}
            </div>
          </div>

          <div>
            <h3 className='text-lg xl:text-[24px] leading-[32px] font-semibold text-start break-words pb-2 '>{heading}</h3>
            
              <p className='text-[14px] leading-[24px] font-extralight text-start py-4  sm:py-0' dangerouslySetInnerHTML={{ __html: paragragh + "..." }} />
            
          </div>
        </div>
        <a href={href} >
          {language_web === "ARE" ? (
            <label className='text-[14px] leading-[24px] font-extralight text-blue-500 text-start '>  المزيد </label>
          ) : (
            <label className='text-[14px] leading-[24px] font-extralight text-blue-500 text-start '>Read More</label>
          )}

        </a>


      </div>
    </div>
  )
}




