import moment from 'moment/moment'
import * as React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'


const maxTimeMinDay = new Date().setHours(16, 0, 0, 0)
const minTimeMaxDay = new Date().setHours(8, 0, 0, 0)
//const startDate = new Date().getDate()
// const daysFromRefDate = (date) =>
// date &&Math.floor((date.getTime() - date.getTimezoneOffset() * 60000) / 86400000)

const DateTimeCalendar = ({ initDate, selected,required}) => {
  // const [selDate, setSelDate] = React.useState(initDate)
  // const changeDate = React.useCallback((date) => setSelDate(date), [])

  // const minDays = React.useMemo(() => daysFromRefDate(minDate), [minDate])
  // const maxDays = React.useMemo(() => daysFromRefDate(maxDate), [maxDate])
  // const selDays = React.useMemo(() => daysFromRefDate(selDate), [selDate])
  // const isMinDay = selDays === minDays
  // const isMaxDay = selDays === maxDays

  //const minTime = isMinDay ? minDate : isMaxDay ? minTimeMaxDay : null
  //const maxTime = isMaxDay ? maxDate : isMinDay ? maxTimeMinDay : null

  // const WeekDays = () => {
  //   const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
  //   return (

  //     days.forEach((day, index) => {
  //       // Check if the index of day value is equal to the returned value of getDay()
  //       if (index == new Date().getDay()) {


  //         console.log("Today is " + day)
  //       }
  //     }
  //     )
  //   )
  // }

  

  //const date = moment().format("yyyy-MM-dd");

  const today = new Date()
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate() + 1)
  
  return (
    <DatePicker className='focus:ring-0 appearance-none focus:outline-none'
      selected={selected}
      onChange={initDate}
      maxDate={tomorrow.setDate(today.getDate() + 1)}
      minDate={new Date()}
      placeholderText={"Choose Date*"}
      required={required}
    //dateFormat="Pp"
    // startDate={startDate}
    // filterDate={WeekDays}
  
    //showTimeSelect={true}
    // timeFormat="HH:mm"
    // dateFormat="MM/dd/yyyy"
    format='yyyy-MM-dd'
    />

  )
}

export default DateTimeCalendar;

