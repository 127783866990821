import React, { useState } from 'react';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; 
// import { Navigate, useNavigate } from "react-router-dom";

const RegistrationForm = ({setType}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    city: '',
    state: '',
    country: '',
    password: '',
  });

  // eslint-disable-next-line 
  const [mobileCountryCode, setMobileCountryCode] = useState(''); // eslint-disable-next-line
  const [formErrors, setFormErrors] = useState({});
  const [message, setMessage] = useState('');


  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      mobileNumber: value,
    });
     setMobileCountryCode(country.dialCode);
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const togglePasswordVisibility = () => {
    setFormData({
      ...formData,
      showPassword: !formData.showPassword,
    });
  };

    // const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();

    // This object is sent to the API
    const requestData = {
      name: formData.name,
      email: formData.email,
      mobileNumber: formData.mobileNumber,
      city: formData.city,
      state: formData.state,
      country: formData.country,
      password: formData.password,
    };



    const validatePassword = (password) => {
      // Password validation rules
      const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%^&*])(.{8,})$/;
    
      if (!password.match(passwordRegex)) {
        const errorMessage =
          'Password must contain at least one uppercase letter, one number, one special character, and be at least 8 characters long.';
    
        setFormErrors({ password: errorMessage });
    
        setTimeout(() => {
          setFormErrors({ password: '' });
        }, 8000);
    
        return errorMessage;
      }
    
      return '';
    };
    

    // Password validation
    const passwordError = validatePassword(formData.password);
    if (passwordError) {
      setFormErrors({ password: passwordError });
      return; 
    }

    try {
      // POST request to API
      const response = await axios.post(
        'https://api.binhaider.dev.client.kloudlite.io/userauthor/register',
        requestData
      );

      if (response.status === 200) {
        setMessage('Registration successful');
        setFormErrors({});
        setTimeout(() => {
          setType('login'); 
        }, 2000);
        
      } else {
        setFormErrors(response.data.errors);
        setMessage('Registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-y-4">
        <div>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Full Name*"
            required
            className="w-full p-2 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray text-true-gray-400"
          />
          {formErrors.name && (
            <span className="text-red-600 text-sm">{formErrors.name}</span>
          )}
        </div>

        <div>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email Address*"
            required
            className="w-full p-2 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray text-true-gray-400"
          />
          {formErrors.email && (
            <span className="text-red-600 text-sm">{formErrors.email}</span>
          )}
        </div>

        <div className="relative">
          <PhoneInput
            country={'ae'}
            placeholder=''
            value={formData.mobileNumber}
            onChange={handlePhoneChange}
            inputProps={{
              name: 'mobileNumber',
              className: 'w-full p-2 pl-12 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray text-true-gray-400',
            }}
            dropdownStyle={{ 
              backgroundColor: 'gray', 
            }}
            
            buttonStyle={{ 
              backgroundColor: 'gray', 
              borderColor:'gray'
            }}
          />
          {formErrors.mobileNumber && (
            <span className="text-red-600 text-sm">{formErrors.mobileNumber}</span>
          )}
        </div>

        {/* <div>
          <input
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            placeholder="City*"
            required
            className="w-full p-2 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray text-true-gray-400"
          />
          {formErrors.city && (
            <span className="text-red-600 text-sm">{formErrors.city}</span>
          )}
        </div> */}

        <div>
          <input
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            placeholder="State*"
            required
            className="w-full p-2 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray text-true-gray-400"
          />
          {formErrors.state && (
            <span className="text-red-600 text-sm">{formErrors.city}</span>
          )}
        </div>

        <div>
          <input
            type="text"
            name="country"
            value={formData.country}
            onChange={handleChange}
            placeholder="Country*"
            required
            className="w-full p-2 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray text-true-gray-400"
          />
          {formErrors.country && (
            <span className="text-red-600 text-sm">{formErrors.country}</span>
          )}
        </div>

        <div>
          <div className="relative">
            <input
              type={formData.showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password*"
              required
              className="w-full p-2 focus:border-primary focus:outline-none bg-true-gray-700 sm:text-sm text-true-gray-400"
            />
            <span
              className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {formData.showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </span>
           
          </div>
           {formErrors.password && (
              <span className="text-red-600 block w-72 mt-2 text-sm">{formErrors.password}</span>
            )}
        </div>


        <div className="pt-4">
          <button
            type="submit"
            className="w-full bg-primary text-white py-2 rounded hover:bg-primary-dark"
          >
            Register
          </button>
        </div>
      </div>

      {message && (
        <div className={`text-${message === 'Registration successful' ? 'green' : 'red'}-600`}>
          {message}
        </div>
      )}
    </form>
  );
};

export default RegistrationForm;
