import React, { useCallback, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Breadcrumb } from "../component/Breadcrumb";
import { UserLayout } from "../Layout/UserLayout";
import { BlogCard } from "../component/Card";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import cinstance from "../api/axios";
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux'


const ClientBlog = () => {


  const [allBlogData, setAllBlogData] = useState([]);

  const [allBlogData1, setAllBlogData1] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);

  const [first, setFirst] = useState(0)



  const language_web = useSelector((state) => state.webLanguage.value)

  const getBlogs = useCallback(async () => {
    try {
      await cinstance({
        url: `/blog/all-blog`,
        method: "GET",
      }).then((res) => {
        setAllBlogData([])
        setAllBlogData1([])
        res.data.map((value, id) => {
          if (value.status == "active")
            setAllBlogData((prev) => [...prev, value]);

        })
      });
    } catch (e) {
      console.error(e);
    }
  }, []);


  useEffect(() => {
    getBlogs();
    return () => { };
  }, []);



  const navigate = useNavigate()

  const itemsPerPage = 6;

  const endOffset = itemOffset + itemsPerPage;
  //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = allBlogData?.slice(1).slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allBlogData?.slice(1)?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allBlogData?.slice(1)?.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };



  {/***Tab and mobile view */ }

  const itemsPerPages = 6;

  const endOffsets = first + itemsPerPages;
  const currentItem = allBlogData.slice(first, endOffsets);
  const pageCounts = Math.ceil(allBlogData?.length / itemsPerPages);

  const handleClick = (event) => {
    const newOffsets = (event.selected * itemsPerPages) % allBlogData?.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setFirst(newOffsets);
  };



  return (
    <UserLayout>
      <div className="flex">
        <div className="hidden lg:block">
          <Breadcrumb image={"blog-banner"} />
        </div>
      </div>
      {/* label={language_web === "ARE" ? ("مدونة") : ("Blog")} */}
      {/* <div className='bg-blog-banner'></div>  */}


      <div className="container mx-auto px-4 lg:px-0  pb-4 lg:pt-10 xl:py-[70px]">
        <div className="flex flex-wrap xl:justify-between justify-center items-center gap-6 sm:gap-0">
          <div className="object-contain h-auto lg:w-[770px] lg:h-[430px]">
            <Carousel
              autoPlay={true}
              showThumbs={false}
              infiniteLoop={true}
              showArrows={false}
              stopOnHover={false}
            >
              {allBlogData?.slice(0, 5)?.map((ele, idx) => (
                <div key={idx} style={{ backgroundColor: `rgba(0,0,0,0.6)` }}>
                  <img src={ele?.[language_web]?.img} className="h-auto w-auto lg:w-[560px] xl:w-[770px] max-h-[424px] object-contain opacity-60" />
                  {language_web === 'ARE' ? (<div className="arabic-format   text-white-wind absolute inset-0 top-[60px] right-[10px] sm:top-[60%] md:right-8">
                    <div className="text-start pl-8">
                      <span className="text-[16px] leading-3 sm:text-lg font-semibold sm:leading-8">{ele?.[language_web]?.title}</span>
                      {<p className=' text-[12px] sm:text-sm xl:text-sm font-extralight break-all leading-6' dangerouslySetInnerHTML={{ __html: ele?.[language_web]?.desc?.slice(0, 52) }} />}

                    </div>

                  </div>) : (<div className="text-white-wind  arabic-format1 absolute inset-0 top-[60px] right-[10px] sm:top-[60%] md:right-[45%]">
                    <div className="text-start pl-8">
                      <span className="text-[16px] leading-3 sm:text-lg font-semibold sm:leading-8">{ele?.[language_web]?.title}</span>

                      {<p className='text-[12px]  sm:text-sm xl:text-sm font-extralight break-all leading-6' dangerouslySetInnerHTML={{ __html: ele?.[language_web]?.desc.slice(0, 52) }} />}

                    </div>

                  </div>)}

                </div>
              ))}

            </Carousel>
          </div>





          <div className=" hidden lg:block pt-4 xl:pt-0">
            <div className="transform transition duration-500 hover:scale-105 ">

              <BlogCard
                onclick={() => navigate(`/blog-view?blog_id=${allBlogData[0]?._id}`)}
                src={allBlogData ? allBlogData[0]?.[language_web]?.img : null}
                alt={"post_one"}
                title={language_web === "ARE" ? ("مدونة") : ("Blog")}
                date={allBlogData ? allBlogData[0]?.createdAt : ""}
                heading={allBlogData ? allBlogData[0]?.[language_web]?.title : ""}
                description={allBlogData ? allBlogData[0]?.[language_web]?.desc : ""}
              />
            </div>
          </div>


        </div>
      </div>
      <div className="container mx-auto pb-[70px] px-4 sm:px-0">
        <div className='flex flex-wrap justify-between items-center pb-4 sm:pb-0'>
          <h1 className='font-semibold text-white text-4xl  pb-2 pl-2 sm:pl-0 sm:pb-8'>{language_web === "ARE" ? ("مدونة") : ("Blog")}</h1>
          <div className="react-paginate block lg:hidden">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handleClick}
              pageRangeDisplayed={4}
              pageCount={pageCounts}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div>
          <div className="react-paginate hidden lg:block">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>


        <div className="flex flex-wrap xl:justify-start justify-center items-center gap-y-[56px] md:gap-2 lg:gap-8 xl:gap-6 ">
          <div>
            <div className="block lg:hidden">
              {/***Tab and mobile view */}
              {currentItem?.map((value) => {

                return (
                  value.status === "active" ? (
                    // <AnimationOnScroll  animateIn="animate__delay-200 animate__animated animate__fadeInLeft">
                    <div key={value._id} className="transform transition duration-500 hover:scale-90 lg:hover:scale-105">
                      <BlogCard
                        onclick={() => navigate(`/blog-view?title=${value?.[language_web]?.title}&blog_id=${value._id}`)}
                        src={value?.[language_web]?.img}
                        title={language_web === "ARE" ? ("مدونة") : ("Blog")}
                        date={value?.createdAt}
                        heading={value?.[language_web]?.title}
                        description={value?.[language_web]?.desc}
                      />
                    </div>
                    // </AnimationOnScroll>
                  ) : null

                );
              })}
            </div>
          </div>
        </div>

        <div className=" hidden   lg:flex flex-wrap xl:justify-start justify-center items-center gap-y-[56px] md:gap-2 lg:gap-8 xl:gap-6 ">
         



              {/***Desktop view */}
              {currentItems?.map((value) => {

                return (
                  value.status === "active" ? (
                    // <AnimationOnScroll  animateIn="animate__delay-200 animate__animated animate__fadeInLeft">
                    <div key={value._id} className="transform transition duration-500 hover:scale-105">
                      <BlogCard
                        onclick={() => navigate(`/blog-view?title=${value?.[language_web]?.title}&blog_id=${value._id}`)}
                        src={value?.[language_web]?.img}
                        title={language_web === "ARE" ? ("مدونة") : ("Blog")}
                        date={value?.createdAt}
                        heading={value?.[language_web]?.title}
                        description={value?.[language_web]?.desc}
                      />
                    </div>
                    // </AnimationOnScroll>
                  ) : null

                );
              })}


           
        </div>


      </div>
    </UserLayout>
  );
};

export default ClientBlog;
