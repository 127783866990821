import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: "en",
}

export const languageSlice = createSlice({
  name: 'webLanguage',
  initialState,
  reducers: {
   
    changeWebLanguage: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeWebLanguage } = languageSlice.actions

export default languageSlice.reducer